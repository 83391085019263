/* eslint-disable arrow-body-style */
import React, { useRef } from 'react';
import AiHeadshotButton from 'components/AiHeadshotButton';
import ProfileAvatar from 'components/ProfileAvatar';
import { ArrowLeft, Zap } from 'lucide-react';
import { useRedirectToWorkspace } from 'hooks/workspace';
import HeadwayWidget from 'components/HeadwayWidget';
import { isMatchingURL } from 'common/utils';
import SaveSignatureDialog from 'components/SaveSignatureDialog';
import { useSaveSignature } from 'hooks/signature';
import { useFeatures } from 'hooks/user';
import { useFeatureChecks } from 'hooks/brand';
import Logo from 'components/Logo';
import { Button } from 'components/ui/button';
import { useAppContext } from 'AppContext';

const Header = () => {
  const {
    state: { signature },
  } = useAppContext();
  const { redirectToWorkspace } = useRedirectToWorkspace();
  const { isSignatureChanged, saveChanges } = useSaveSignature();
  const { isAIHeadshotsAllowed } = useFeatureChecks();

  const saveSignatureDialogRef = useRef();
  const { isUserUsingProFeatures } = useFeatures();

  const handleCheckSaveChanges = () => {
    if (isSignatureChanged) {
      saveSignatureDialogRef?.current?.open();
    } else {
      redirectToWorkspace();
    }
  };

  const handleSaveChanges = () => {
    saveChanges();
    saveSignatureDialogRef?.current?.close();
    if (!isUserUsingProFeatures()) {
      redirectToWorkspace();
    }
  };
  return (
    <>
      <SaveSignatureDialog
        handleSaveChanges={handleSaveChanges}
        ref={saveSignatureDialogRef}
      />
      <nav className="bg-[#FFFBFE] flex justify-between h-[70px] border-solid border-0 border-b-1 border-primary">
        <div className="flex items-center pl-[16px]">
          {/* <Logo onClick={handleCheckSaveChanges} />
          {isMatchingURL() && <HeadwayWidget />} */}
          <h1 className="flex items-center font-primary text-h6 font-bold text-neutral-1000">
            <Button
              onClick={handleCheckSaveChanges}
              variant="text"
              className="px-0 py-0 mr-2"
            >
              <ArrowLeft />
            </Button>
            {signature?.name || ''}
          </h1>
          {isMatchingURL() && <HeadwayWidget />}
        </div>


        <div className="flex items-center pr-[16px]">
          {/* {isAIHeadshotsAllowed && (
            <AiHeadshotButton className=" mr-4 flex justify-center bg-gradient-to-r from-[#8557E9] via-[#BC57E6] to-[#F357E3]">
              Try AI Headshot
              <Zap size={16} className="ml-[6px] fill-white-0" />
            </AiHeadshotButton>
          )} */}

          {/* <ProfileAvatar /> */}
        </div>
      </nav>
    </>
  );
};

export default Header;
