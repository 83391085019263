import React from 'react';
import { get } from 'lodash';
import { getFieldData, getLiveUrl } from 'common/utils';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Phone from './components/Phone';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';
import CompanyPhone from './components/CompanyPhone';
import CompanyEmail from './components/CompanyEmail';

function TemplateStyleEight({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl =
    signature?.headshot?.config?.output ??
    `${getLiveUrl(signature?.headshot?.config?.output)}`;
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef } = useTableWidth(downloadUrl);
  const { fields, design } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const section1 =
    !getFieldData(fields, 'headshotUrl') &&
    !getFieldData(fields, 'name') &&
    !getFieldData(fields, 'position') &&
    !getFieldData(fields, 'department') &&
    !getFieldData(fields, 'companyName');

  const section3 =
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyEmail') &&
    !getFieldData(fields, 'companyPhone') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website');

  const section5 = socialFields?.every((obj) => obj?.value === '');

  return (
    <table
      cellPadding="0"
      cellSpacing="0"
      border="0"
      style={{
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      {getFieldData(fields, 'signOff') && (
        <tr>
          {SignOff({
            signature,
            data: getFieldData(fields, 'signOff'),
            style: {
              fontWeight: 700,
              fontFamily: 'inherit',
            },
          })}
        </tr>
      )}
      <tr>
        <td>
          <table cellPadding={0}>
            <tr>
              <table
                ref={mainContentRef}
                align="center"
                cellPadding="0"
                cellSpacing="0"
                border="0"
                style={{
                  fontFamily: font,
                  userSelect: 'none',
                }}
                role="presentation"
              >
                {!section1 && (
                  <tr>
                    <td
                      align="center"
                      style={{
                        paddingBottom: '6px',
                      }}
                    >
                      <table cellPadding={0}>
                        {getFieldData(fields, 'headshotUrl') && (
                          <tr align="center">
                            <HeadshotImage
                              data={getFieldData(fields, 'headshotUrl')}
                              design={design}
                            />
                          </tr>
                        )}
                        {getFieldData(fields, 'name') && (
                          <tr>
                            {Name({
                              signature,
                              data: getFieldData(fields, 'name'),
                              style: {
                                fontWeight: 700,
                                color: design?.primaryBrandColor,
                                textAlign: 'center',
                              },
                            })}
                          </tr>
                        )}
                        {getFieldData(fields, 'companyName') && (
                          <tr>
                            {CompanyName({
                              signature,
                              data: getFieldData(fields, 'companyName'),
                              style: {
                                textAlign: 'center',
                              },
                            })}
                          </tr>
                        )}
                        {getFieldData(fields, 'position') && (
                          <tr>
                            {Position({
                              signature,
                              data: getFieldData(fields, 'position'),
                              style: {
                                textAlign: 'center',
                              },
                            })}
                          </tr>
                        )}
                        {getFieldData(fields, 'department') && (
                          <tr>
                            {Department({
                              signature,
                              data: getFieldData(fields, 'department'),
                              style: {
                                textAlign: 'center',
                              },
                            })}
                          </tr>
                        )}
                      </table>
                    </td>
                  </tr>
                )}
                {!(section1 || section3) && (
                  <tr>
                    <Divider signature={signature} design={design} />
                  </tr>
                )}
                {!section3 && (
                  <tr>
                    <td align="center">
                      <table
                        cellPadding="0"
                        cellSpacing="0"
                        border="0"
                        role="presentation"
                        width="max-content"
                        style={{
                          fontSize: 'inherit',
                        }}
                      >
                        {(getFieldData(fields, 'phone') ||
                          getFieldData(fields, 'email')) && (
                          <tr>
                            {getFieldData(fields, 'phone') &&
                              Phone({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'phone'),
                                style: {
                                  paddingRight: '8px',
                                },
                              })}
                            {getFieldData(fields, 'email') &&
                              Email({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'email'),
                              })}
                          </tr>
                        )}
                        {(getFieldData(fields, 'companyPhone') ||
                          getFieldData(fields, 'companyEmail')) && (
                          <tr>
                            {getFieldData(fields, 'companyPhone') &&
                              CompanyPhone({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'companyPhone'),
                                style: {
                                  paddingRight: '8px',
                                },
                              })}
                            {getFieldData(fields, 'companyEmail') &&
                              CompanyEmail({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'companyEmail'),
                                style: {},
                              })}
                          </tr>
                        )}

                        {(getFieldData(fields, 'website') ||
                          getFieldData(fields, 'companyAddress')) && (
                          <tr>
                            {getFieldData(fields, 'website') &&
                              Website({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'website'),
                                style: {
                                  paddingRight: '8px',
                                },
                              })}
                            {getFieldData(fields, 'companyAddress') &&
                              CompanyAddress({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'companyAddress'),
                              })}
                          </tr>
                        )}
                      </table>
                    </td>
                  </tr>
                )}
                {!(section5 || section3) && (
                  <tr>
                    <tr>
                      <Divider signature={signature} design={design} />
                    </tr>
                  </tr>
                )}
                {!!socialFields?.length && (
                  <tr>
                    <td align="center">
                      <table cellPadding="0">
                        <tr>
                          {SocialLinks({
                            signature,
                            showAwsIcons,
                            data: socialFields,
                            design,
                          })}
                        </tr>
                      </table>
                    </td>
                  </tr>
                )}
                {getFieldData(fields, 'companyLogo') && (
                  <tr align="center">
                    {CompanyLogo({
                      data: getFieldData(fields, 'companyLogo'),
                      design,
                      toProps: { colSpan: 2 },
                      imagestyle: {
                        paddingBottom: '8px',
                      },
                    })}
                  </tr>
                )}
              </table>
            </tr>

            {getFieldData(fields, 'ctaUrl') &&
              getFieldData(fields, 'ctaTitle') && (
                <tr align="center">
                  {CallToAction({
                    signature,
                    showAwsIcons,
                    design,
                    data: {
                      ctaUrl: getFieldData(fields, 'ctaUrl'),
                      ctaTitle: getFieldData(fields, 'ctaTitle'),
                    },
                    tableprops: { align: 'center' },
                  })}
                </tr>
              )}
            {getFieldData(fields, 'ctaBannerImg') &&
              getFieldData(fields, 'ctaBannerUrl') && (
                <tr align="center">
                  {CallToActionBanner({
                    data: {
                      ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
                      ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
                    },
                    design,
                  })}
                </tr>
              )}
            {!!getFieldData(fields, 'socialBanners')?.value?.length && (
              <tr>
                {SocialBanners({
                  data: getFieldData(fields, 'socialBanners'),
                  design,
                  style: {
                    textAlign: 'center',
                  },
                })}
              </tr>
            )}
            {getFieldData(fields, 'footer') && (
              <tr align="center">
                {Footer({
                  data: getFieldData(fields, 'footer'),
                  design,
                })}
              </tr>
            )}
          </table>
        </td>
      </tr>

      <tr>
        <Branding hideBranding={hideBranding} />
      </tr>
    </table>
  );
}

export default TemplateStyleEight;
