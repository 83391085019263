import { useAppContext } from 'AppContext';
import React, { useEffect, useRef, useState } from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';
import { useRouter } from 'hooks/router';
import {
  useGetSignature,
  useSaveSignature,
  useShowBranding,
  useShowBrandingText,
  useSignatureId,
} from 'hooks/signature';
import { useGetHeadshot } from 'hooks/headshot';
import InformationControl from 'modules/Information/InformationControl';
import HeadshotControl from 'modules/Headshot/HeadshotControl';
import DesignControl from 'modules/Design/DesignControl';
import CallToActionControl from 'modules/CallToAction/CallToActionControl';
import LoaderComponent from 'components/LoaderComponent';
import { signatureRoutes } from 'routes';
import { useGetRoutes } from 'helpers/useGetRoutes';
import RouterPrompt from 'components/RouterPrompt';
import { useWindowSize } from 'helpers/useWindowSize';
import MobileHeadshot from 'modules/Headshot/MobileHeadshot';
import { HEADSHOT_DATA, PRIMARY_COLOR } from 'common/constants';
import { useRedirect } from 'hooks/auth';
import { cn } from 'lib/utils';
import { useWorkspaceId } from 'hooks/workspace';
import { keyBy, partition } from 'lodash';
import TemplatesModel from 'components/TemplatesModel';
import { validate as isValidUuid } from 'uuid';
import { useFeatures, useFreeTrial, useGetWorkspaceOwner } from 'hooks/user';
import SubscriptionModal from 'components/SubscriptionModal';
import SideBar from './SideBar';
import Header from './Header';
// const { Content, Sider } = Layout;

const SignatureLayout = () => {
  const { getRoutes } = useGetRoutes();
  const {
    state: { signature, headshot },
    setHeadshot,
    setSignature,
  } = useAppContext();

  useShowBranding();
  useShowBrandingText();

  const {
    location: { pathname },
  } = useRouter();
  const {
    location: { search },
  } = useRouter();
  const { isPlanExpired, redirectToPlan } = useFreeTrial();

  const params = new URLSearchParams(search);
  const showTemplate = params.get('showTemplate');
  const isCreate = params.get('isCreate');
  const primaryColorPersists = params.get('primaryColorPersists') === true;
  const templatesModalRef = useRef();
  const informationControlModalRef = useRef();
  const { workspaceId } = useWorkspaceId();
  const { isSignatureChanged } = useSaveSignature();
  const { signatureId } = useSignatureId();
  const [getSignature] = useGetSignature();
  const [getHeadshot] = useGetHeadshot();
  const { size } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const { redirectUser } = useRedirect();
  const [getWorkspaceOwner] = useGetWorkspaceOwner(); // Use the custom hook here

  useEffect(() => {
    if (isPlanExpired) redirectToPlan();
  }, [isPlanExpired]);

  useEffect(() => {
    if (showTemplate) {
      templatesModalRef?.current?.open();
    }
  }, [showTemplate]);

  const handleTemplateSelect = async (data) => {
    const updatedSignature = { ...signature };
    // Create a new fields array
    const newFields = data.fields;

    const variableFieldsName = [
      'name',
      'position',
      'department',
      'phone',
      'email',
      'companyLogo',
      'headshotUrl',
    ];
    const signatureFields = [...(signature?.fields || [])];
    const [variableFields, nonVariableFields] = partition(newFields, (field) =>
      variableFieldsName.includes(field.name),
    );

    const nonVariableFieldsMap = keyBy(nonVariableFields, 'name');

    // Replace objects in nonVariableFields with those in variableFields if they have the same name
    const updatedFields = signatureFields.map((field) => {
      if (nonVariableFieldsMap[field.name]) {
        return nonVariableFieldsMap[field.name];
      }
      return field;
    });

    const submutData = {
      ...updatedSignature,
      fields: updatedFields,
      cta: data?.cta,
      template: data?.template || updatedSignature.template,
      design:
        {
          ...updatedSignature.design,
          font: data?.design?.font,
          styles: data?.design?.styles,
        } || data?.design,
      templateId: data?.templateId || updatedSignature.templateId,
    };

    if (isCreate === 'true') {
      submutData.headshotUrl = data?.headshotUrl;
      submutData.fields = newFields;
      submutData.design = data?.design;
    }
    if (isCreate === 'false') {
      submutData.fields = signatureFields;
      submutData.design = data?.design;
      if (primaryColorPersists) {
        submutData.design.primaryBrandColor =
          updatedSignature?.design?.primaryBrandColor;
      }
    }

    const form = informationControlModalRef?.current?.form;
    if (form) {
      form.reset({
        ...submutData?.fields?.reduce((acc, field) => {
          acc[field.name] = field.value || '';
          return acc;
        }, {}),
      });
    }

    setSignature(submutData);
    templatesModalRef?.current?.close();
  };

  // ROUTE_CHANGE to HIDE_sidebar
  const hideSidebar =
    pathname !== `/app/${workspaceId}/signature/${signatureId}/email-signature`;

  const loadSignatureData = async () => {
    setLoading(true);
    const res = await getSignature({
      variables: {
        where: { signatureId: signatureId || signature?.id || '', workspaceId },
      }, // signature id for QUERY
    });
    await getWorkspaceOwner({
      variables: {
        where: {
          id: workspaceId,
        },
      },
    });
    const headshotId = res?.data?.signature?.headshotId;
    if (headshotId && res?.data?.signature?.headshot) {
      await getHeadshot({
        variables: {
          where: {
            id: headshotId,
          },
        },
      });
    } else {
      setHeadshot(HEADSHOT_DATA);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (signatureId) {
      if (
        signatureId === 'undefined' ||
        signatureId === 'null' ||
        signatureId === null
      ) {
        redirectUser();
      }
      loadSignatureData();
    }
  }, [signatureId]);

  const pathsToHide = ['analytics', 'email-signature'];
  const hideControls = pathsToHide.some((path) => pathname.includes(path));

  const getDefaultPath = () => {
    if (isValidUuid(workspaceId) && isValidUuid(signatureId)) {
      return `/app/${workspaceId}/signature/${signatureId}/information`;
    }
    return null; // or handle the invalid case as needed
  };

  const defaultPath = getDefaultPath();

  const getControlComponent = () => {
    switch (true) {
      case pathname.includes('information'):
        return <InformationControl ref={informationControlModalRef} />;
      case pathname.includes('headshot'):
        return <HeadshotControl />;
      case pathname.includes('design'):
        return <DesignControl />;
      case pathname.includes('cta'):
        return <CallToActionControl />;
      default:
        return null;
    }
  };

  if (['xs', 'sm', 'md'].includes(size)) {
    return <MobileHeadshot />;
  }

  return (
    <div className="relative">
      <SubscriptionModal />
      <TemplatesModel
        ref={templatesModalRef}
        handleTemplateSelect={handleTemplateSelect}
      />
      <RouterPrompt openPrompt={isSignatureChanged} />
      <Header />
      <div
        className={cn('grid grid-cols-[100px_400px_1fr]', {
          'grid-cols-[100px_0px_1fr]': hideControls,
          'grid-cols-[0px_0px_1fr]': !hideSidebar,
        })}
        style={{ height: 'calc(100vh - 71px)', overflow: 'auto' }}
      >
        <SideBar />
        {loading ? (
          <LoaderComponent setWidth="100%" />
        ) : (
          <>
            <div
              className="bg-primary-foreground"
              style={{
                height: 'calc(100vh - 71px)',
                overflow: 'auto',
              }}
            >
              <div style={{ overflow: 'hidden', width: '100%' }}>
                {getControlComponent()}
              </div>
            </div>
            <div
              className="w-full bg-primary-foreground"
              style={{
                height: 'calc(100vh - 71px)',
                overflow: 'auto',
                margin: 0,
              }}
              trigger={null}
            >
              <Routes>
                {getRoutes(signatureRoutes)}
                <Route
                  path="*"
                  element={<Navigate to={defaultPath || '/app/'} />}
                />
              </Routes>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SignatureLayout;
