import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { Button } from 'components/ui/button';

const DeleteForm = forwardRef(({ onConfirm }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  }));

  const handleConfirm = () => {
    onConfirm();
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="rounded-[6px] max-w-[400px]">
        <DialogHeader>
          <DialogTitle>Remove Profile</DialogTitle>
          <DialogDescription>
            Are you sure you want to remove your profile? This action cannot be
            undone.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <div className="flex w-full justify-end gap-3 ">
            <Button
              type="button"
              className="w-[max-content]"
              onClick={() => setIsOpen(false)}
              variant="outline"
              size="sm"
            >
              Cancel
            </Button>
            <Button onClick={handleConfirm} size="sm">
              Confirm
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
});

export default DeleteForm;
