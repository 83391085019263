/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Phone from './components/Phone';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';
import CompanyPhone from './components/CompanyPhone';
import CompanyEmail from './components/CompanyEmail';

function TemplateStyleOne({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  // const headshot = signature?.headshot;

  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const section1 =
    !getFieldData(fields, 'headshotUrl') &&
    !getFieldData(fields, 'companyLogo');

  const section2 =
    !getFieldData(fields, 'name') &&
    !getFieldData(fields, 'position') &&
    !getFieldData(fields, 'department') &&
    !getFieldData(fields, 'companyName');

  const section3 =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website') &&
    !getFieldData(fields, 'companyEmail') &&
    !getFieldData(fields, 'companyPhone');

  const ctasection =
    !getFieldData(fields, 'ctaUrl') &&
    !getFieldData(fields, 'ctaTitle') &&
    !getFieldData(fields, 'ctaBannerImg') &&
    !getFieldData(fields, 'ctaBannerUrl');

  const separatorWidth = get(design, 'styles.separator.style.width', 1);
  const separatorColor = get(
    design,
    'styles.separator.style.color',
    design?.primaryBrandColor,
  );
  const separatorType = get(design, 'styles.separator.style.type', 'solid');

  return (
    <table
      cellPadding="0"
      cellSpacing="0"
      border="0"
      style={{
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      {getFieldData(fields, 'signOff') && (
        <tr>
          {SignOff({
            signature,
            data: getFieldData(fields, 'signOff'),
            style: {
              fontWeight: 700,
              fontFamily: 'inherit',
              paddingBottom: '2px',
            },
          })}
        </tr>
      )}
      <tr>
        <td aria-label="main-content">
          <table
            cellPadding="0"
            cellSpacing="0"
            border="0"
            style={{
              borderCollapse: 'collapse',
              fontSize: 'inherit',
              paddingBottom: '10px',
            }}
          >
            <tr>
              <td>
                <table
                  cellPadding="0px"
                  cellSpacing="0"
                  border="0"
                  ref={mainContentRef}
                  style={{
                    paddingBottom: '8px',
                  }}
                >
                  <tr>
                    {!section1 && (
                      <td
                        style={{
                          verticalAlign: 'top',
                          paddingRight: '14px',
                        }}
                      >
                        <table
                          cellPadding="0"
                          cellSpacing="0"
                          style={{
                            fontSize: 'inherit',
                          }}
                        >
                          {getFieldData(fields, 'headshotUrl') && (
                            <tr align="center">
                              {HeadshotImage({
                                data: getFieldData(fields, 'headshotUrl'),
                                design,
                                style: {
                                  paddingBottom: '8px',
                                },
                              })}
                            </tr>
                          )}
                          {getFieldData(fields, 'companyLogo') && (
                            <tr align="center">
                              {CompanyLogo({
                                data: getFieldData(fields, 'companyLogo'),
                                design,
                                style: {
                                  paddingBottom: '8px',
                                },
                              })}
                            </tr>
                          )}
                        </table>
                      </td>
                    )}

                    <td
                      style={{
                        verticalAlign: 'top',
                      }}
                    >
                      <table
                        cellPadding="0px"
                        cellSpacing="0"
                        border="0"
                        style={{
                          borderCollapse: 'collapse',
                        }}
                      >
                        {!section2 && (
                          <tr>
                            <td>
                              <table
                                cellPadding="0"
                                cellSpacing="0"
                                border="0"
                                style={{
                                  fontSize: 'inherit',
                                  paddingBottom: '6px',
                                }}
                              >
                                {getFieldData(fields, 'name') && (
                                  <tr>
                                    {Name({
                                      signature,
                                      data: getFieldData(fields, 'name'),
                                      style: {
                                        fontWeight: 700,
                                        color: design?.primaryBrandColor,
                                        fontFamily: 'inherit',
                                        paddingBottom: '2px',
                                      },
                                    })}
                                  </tr>
                                )}
                                {getFieldData(fields, 'position') && (
                                  <tr>
                                    {Position({
                                      signature,
                                      data: getFieldData(fields, 'position'),
                                      style: {
                                        fontFamily: 'inherit',
                                        paddingBottom: '2px',
                                      },
                                    })}
                                  </tr>
                                )}
                                {(getFieldData(fields, 'department') ||
                                  getFieldData(fields, 'companyName')) && (
                                  <tr>
                                    <td>
                                      <table
                                        cellPadding="0"
                                        cellSpacing="0"
                                        border="0"
                                        style={{
                                          borderCollapse: 'collapse !important',
                                          fontSize: 'inherit',
                                          paddingBottom: '2px',
                                        }}
                                      >
                                        <tr>
                                          {getFieldData(fields, 'department') &&
                                            Department({
                                              signature,
                                              data: getFieldData(
                                                fields,
                                                'department',
                                              ),
                                              style: {
                                                fontFamily: 'inherit',
                                                paddingRight: '8px',
                                              },
                                            })}

                                          {getFieldData(
                                            fields,
                                            'companyName',
                                          ) &&
                                            CompanyName({
                                              signature,
                                              data: getFieldData(
                                                fields,
                                                'companyName',
                                              ),
                                              style: {
                                                fontFamily: 'inherit',
                                              },
                                            })}
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                )}
                              </table>
                            </td>
                          </tr>
                        )}

                        {!(section3 || section2) && (
                          <tr>
                            <td
                              colSpan={2}
                              style={{
                                borderTop: !(section3 || section2)
                                  ? `${separatorWidth}px ${separatorType} ${separatorColor}`
                                  : '',
                                paddingBottom: '8px',
                              }}
                            />
                          </tr>
                        )}

                        {(getFieldData(fields, 'phone') ||
                          getFieldData(fields, 'email')) && (
                          <tr>
                            {getFieldData(fields, 'phone') &&
                              Phone({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'phone'),
                                style: {
                                  paddingRight: '8px',
                                },
                              })}
                            {getFieldData(fields, 'email') &&
                              Email({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'email'),
                              })}
                          </tr>
                        )}

                        {(getFieldData(fields, 'companyPhone') ||
                          getFieldData(fields, 'companyEmail')) && (
                          <tr>
                            {getFieldData(fields, 'companyPhone') &&
                              CompanyPhone({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'companyPhone'),
                                style: {
                                  paddingRight: '8px',
                                },
                              })}
                            {getFieldData(fields, 'companyEmail') &&
                              CompanyEmail({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'companyEmail'),
                                style: {},
                              })}
                          </tr>
                        )}

                        {getFieldData(fields, 'website') && (
                          <tr>
                            {Website({
                              signature,
                              showAwsIcons,
                              design,
                              data: getFieldData(fields, 'website'),
                            })}
                          </tr>
                        )}
                        {getFieldData(fields, 'companyAddress') && (
                          <tr>
                            {CompanyAddress({
                              signature,
                              showAwsIcons,
                              design,
                              data: getFieldData(fields, 'companyAddress'),
                            })}
                          </tr>
                        )}
                        {!!socialFields?.length && (
                          <tr>
                            {SocialLinks({
                              signature,
                              showAwsIcons,
                              data: socialFields,
                              design,
                              paddingBottom: '6px',
                            })}
                          </tr>
                        )}
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            {getFieldData(fields, 'ctaUrl') &&
              getFieldData(fields, 'ctaTitle') && (
                <tr>
                  {CallToAction({
                    signature,
                    showAwsIcons,
                    design,
                    data: {
                      ctaUrl: getFieldData(fields, 'ctaUrl'),
                      ctaTitle: getFieldData(fields, 'ctaTitle'),
                    },
                    style: { paddingBottom: '16px' },
                  })}
                </tr>
              )}

            {getFieldData(fields, 'ctaBannerImg') &&
              getFieldData(fields, 'ctaBannerUrl') && (
                <tr>
                  {CallToActionBanner({
                    data: {
                      ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
                      ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
                    },
                    design,
                    style: {
                      textAlign: 'left',
                      paddingBottom: '16px',
                    },
                  })}
                </tr>
              )}
            {!!getFieldData(fields, 'socialBanners')?.value?.length && (
              <tr>
                {SocialBanners({
                  data: getFieldData(fields, 'socialBanners'),
                  design,
                  style: {
                    textAlign: 'left',
                  },
                })}
              </tr>
            )}
            {getFieldData(fields, 'footer') && (
              <tr>
                {Footer({
                  data: getFieldData(fields, 'footer'),
                  design,
                  style: {
                    textAlign: 'left',
                  },
                })}
              </tr>
            )}
          </table>
        </td>
      </tr>

      <tr>
        <Branding hideBranding={hideBranding} />
      </tr>
    </table>
  );
}

export default TemplateStyleOne;
