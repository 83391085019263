import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_PLANS = gql`
  query GetAllPlans {
    getAllPlans {
      id
      name
      price
      allowedSignatures
      allowedTemplates
      paymentLink
      features {
        key
        label
      }
    }
  }
`;

export const GET_LM_LTD_PLAN_CHECKOUT_LINK = gql`
  query GetLmLtdPlanCheckoutLink($productName: String!) {
    getLmLtdPlanCheckoutLink(where: { productName: $productName }) {
      url
    }
  }
`;

export const GET_PADDLE_CHECKOUT_TRANSACTION_ID = gql`
  query GetPaddleCheckoutTransactionId($where: PaddleCheckoutInput!) {
    getpaddleCheckoutTransactionId(where: $where) {
      transactionId
    }
  }
`;

export const GET_RECURRING_PLANS = gql`
  query GetRecurringPlans {
    getRecurringPlans {
      id
      name
      price
      allowedSignatures
      allowedTemplates
      paymentLink
      paddlePrices
      features {
        key
        label
      }
    }
  }
`;

export const GET_CANCEL_SUBSCRIPTION_URL = gql`
  query GetCancelSubscriptionUrl {
    getCancelSubscriptionUrl {
      url
    }
  }
`;

export const GET_SUBSCRIPTION_DETAILS = gql`
  query GetSubscriptionDetails($where: SubscriptionWhereInput!) {
    getSubscriptionDetails(where: $where) {
      subscriptionId
      planId
      subscriptionStatus
      startDate
      endDate
      priceId
      billingPeriod
      nextBilledAt
      createdAt
      updatedAt
      canceledAt
    }
  }
`;

export const ACTIVATE_FREE_TRIAL_PLAN = gql`
  mutation ActivateFreeTrialPlan {
    activateFreeTrialPlan {
      data {
        id
        email
        firstName
        lastName
        name
        profileImage
        role
        isActive
        lastActiveOn
        earlyAccess
        onboardingStatus
        isCouponUsed
        planId
        groupId
        signatureLimit
        workspaces
        phone
        department
        position
        freeTrialStartedAt
        isPlanExpired
        activePlan {
          id
          name
          price
          allowedSignatures
          allowedTemplates
          features {
            key
            label
          }
        }
      }
      message
    }
  }
`;
