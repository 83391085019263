/* eslint-disable react/jsx-key */
import { PlusSquareFilled } from '@ant-design/icons';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { Slider } from 'components/ui/slider';
import { DUMMY_HEADSHOT_URL, SOCIAL_LINKS, IMAGE_TYPE } from 'common/constants';
import { Switch } from 'components/ui/switch';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { v4 as uuid } from 'uuid';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { capitalize, get, cloneDeep, partition, keyBy } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Textarea } from 'components/ui/textarea';
import { Button } from 'components/ui/button';
import { socialImages } from 'assets/social-images';
import BaseDragger from 'components/BaseDragger';
import { fileUpload, resizeImage, URL_REGEX } from 'common/utils';
import ImageUploadPreview from 'components/ImageUploadPreview';
import { useRouter } from 'hooks/router';
import { useGetWorkspaceTemplateCompanyLogoSignedUrl } from 'hooks/workspace-template';
import { Input } from 'components/ui/input';
import { SquareMinus } from 'lucide-react';
import CustomEditor from 'components/CustomEditor';
import Cropper from 'components/Cropper';

function WorkspaceTemplateInformation({
  workspaceTemplate,
  handleSetWorkspaceTemplate,
}) {
  const {
    location: { search },
  } = useRouter();
  const [getWorkspaceTemplateSignUrl] =
    useGetWorkspaceTemplateCompanyLogoSignedUrl();

  const [socialPopover, setSocialPopover] = useState(false);
  const [fields, setFields] = useState([...SOCIAL_LINKS]);
  const params = new URLSearchParams(search);
  const workspaceTemplateId = params.get('workspaceTemplateId');

  const logoWidth = get(
    workspaceTemplate,
    'design.styles.companyLogo.style.width',
    80,
  );
  const profilepictureWidth = get(
    workspaceTemplate,
    'design.styles.headshot.style.width',
    98,
  );

  const profilepictureradius = get(
    workspaceTemplate,
    'design.styles.headshot.style.borderRadius',
    5,
  );
  // Reorganize fields
  const profileImageFields = workspaceTemplate?.fields?.filter((field) =>
    ['headshotUrl'].includes(field.name),
  );
  const personalFields = workspaceTemplate?.fields?.filter((field) =>
    ['name', 'position', 'department', 'email', 'phone'].includes(field.name),
  );
  const businessFields = workspaceTemplate?.fields
    ?.filter((field) =>
      [
        'companyName',
        'companyPhone',
        'companyEmail',
        'companyAddress',
        'website',
      ].includes(field.name),
    )
    .sort((a, b) => {
      const order = [
        'companyName',
        'companyPhone',
        'companyEmail',
        'companyAddress',
        'website',
      ];
      return order.indexOf(a.name) - order.indexOf(b.name);
    });
  const companyLogoFields = workspaceTemplate?.fields?.filter((field) =>
    ['companyLogo'].includes(field.name),
  );
  const socialFields = workspaceTemplate?.fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );
  const footerFields = workspaceTemplate?.fields?.filter((field) =>
    ['footer'].includes(field.name),
  );

  const urlValidation = z.string().refine(
    (val) => {
      const urlRegex = URL_REGEX;
      return urlRegex.test(val);
    },
    {
      message: 'URL must start with https://  .',
    },
  );
  const headshotFieldsSchema = profileImageFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );
  const personalFieldsSchema = personalFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );

  const businessFieldsSchema = businessFields?.reduce((acc, field) => {
    if (field.name === 'website') {
      return {
        ...acc,
        [field.name]:
          field.value !== null
            ? urlValidation.optional().or(z.literal(''))
            : z.string().nullable(),
      };
    }
    return {
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    };
  }, {});

  const companyLogoFieldsSchema = companyLogoFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );

  const contanctFieldsSchema = personalFields?.reduce((acc, field) => {
    if (field.name === 'website') {
      return {
        ...acc,
        [field.name]:
          field.value !== null
            ? urlValidation.optional().or(z.literal(''))
            : z.string().nullable(),
      };
    }
    return {
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    };
  }, {});

  const socialFieldsSchema = socialFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? urlValidation.optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );

  const footerFieldsSchema = footerFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );

  const schema = z.object({
    ...(headshotFieldsSchema ?? {}),
    ...(personalFieldsSchema ?? {}),
    ...(businessFieldsSchema ?? {}),
    ...(companyLogoFieldsSchema ?? {}),
    ...(contanctFieldsSchema ?? {}),
    ...(socialFieldsSchema ?? {}),
    ...(footerFieldsSchema ?? {}),
  });

  const form = useForm({
    resolver: zodResolver(schema),
    values: {
      ...workspaceTemplate?.fields?.reduce((acc, field) => {
        acc[field.name] = field.value || '';
        return acc;
      }, {}),
    },
  });

  const watch = form.watch();

  const handleFormChange = () => {
    const values = form.getValues();
    const updatedFields = [...(workspaceTemplate?.fields ?? [])].map(
      (field) => ({
        ...field,
        value: values[field.name] || '',
      }),
    );
    const updatedValues = {
      ...workspaceTemplate,
      fields: updatedFields,
    };
    handleSetWorkspaceTemplate(updatedValues);
  };

  useEffect(() => {
    const subscription = form.watch(() => handleFormChange());
    return () => subscription.unsubscribe();
  }, [form, workspaceTemplate]);

  const generateSignUrl = async (variables) => {
    const res = await getWorkspaceTemplateSignUrl({
      variables: {
        data: {
          ...variables,
        },
      },
    });
    return res?.data?.getWorkspaceTemplateCompanyLogoSignedUrl;
  };
  const handleSocialSelect = (data) => {
    const selectedItem = {
      name: data.name,
      isVariable: false,
      label: capitalize(data.name),
      link: '',
      shortLink: '',
      type: '',
      utagEnabled: true,
      value: '',
      variableValue: null,
    };
    setFields(fields.filter((f) => f.name !== data.name));
    handleSetWorkspaceTemplate({
      ...workspaceTemplate,
      fields: [...workspaceTemplate?.fields, selectedItem],
    });
    setSocialPopover(false);
  };

  const handleFileUpload = async (files, field) => {
    const file = files?.[0];
    if (file) {
      const isGif = file.type === IMAGE_TYPE.gif;
      let uploadFile = file;
      if (!isGif) {
        uploadFile = await resizeImage(file, 200);
      }
      const { name } = uploadFile || {};
      const ext = name.substring(name.lastIndexOf('.') + 1);
      const filename = name.split('.').slice(0, -1).join('.');
      const newFilename = `${filename}.${ext}`;
      const res = await generateSignUrl({
        fileName: newFilename,
        workspaceTemplateId: workspaceTemplateId ?? uuid(),
      });
      if (res) {
        const { signedUrl, key } = res;
        const outputLogoImage = `${process.env.REACT_APP_SOCIAL_ICONS_URL}/${key}`;
        try {
          await fileUpload(signedUrl, file);
          form.setValue(field.name, outputLogoImage);
        } catch (error) {
          return false;
        }
      }
    }
  };

  const onSubmit = (data) => {};

  const handleSwitchChange = (value, name) => {
    const updatedValues = { ...workspaceTemplate };

    const fieldToUpdate = updatedValues.fields?.find(
      (field) => field.name === name,
    );

    if (fieldToUpdate) {
      fieldToUpdate.isVariable = value;
      if (value) {
        const valueToSet =
          name === 'headshotUrl' ? DUMMY_HEADSHOT_URL : fieldToUpdate.label;
        form.setValue(name, valueToSet);
        fieldToUpdate.value = valueToSet;
      } else {
        form.setValue(name, '');
        fieldToUpdate.value = '';
      }
    }

    handleSetWorkspaceTemplate(updatedValues);
  };

  const handleRemoveSocialLink = (name) => {
    handleSetWorkspaceTemplate({
      ...workspaceTemplate,
      fields: workspaceTemplate.fields.filter((field) => field.name !== name),
    });
    setFields([...fields, SOCIAL_LINKS.find((link) => link.name === name)]);
  };

  const addSocialFieldsName = socialFields?.map((f) => f?.name);
  useEffect(() => {
    setFields(fields?.filter((f) => !addSocialFieldsName?.includes(f?.name)));
  }, []);

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="flex-1 overflow-y-auto p-4">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <section>
                <h2 className="text-xl font-semibold text-gray-900 mb-4">
                  Personal Information
                </h2>
                <div className="mb-6">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">
                    Profile Image
                  </h3>
                  {profileImageFields &&
                    profileImageFields?.map((field) => (
                      <FormField
                        key={field.name}
                        control={form.control}
                        name={field.name}
                        render={({ field: formField }) => (
                          <FormItem>
                            <FormControl>
                              <div className="grid grid-cols-[1fr_44px] gap-2 items-center mb-2">
                                <ImageUploadPreview
                                  text="Clear"
                                  onChange={() => {
                                    form.setValue(formField.name, '');
                                    handleSwitchChange(false, formField.name);
                                  }}
                                  imageUrl={watch.headshotUrl}
                                  className="w-[98px] h-[98px] rounded-none"
                                />

                                <Switch
                                  onCheckedChange={(e) =>
                                    handleSwitchChange(e, formField.name)
                                  }
                                  checked={field.isVariable}
                                  // disabled={!headshot?.id}
                                />
                              </div>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    ))}
                  <div className="mb-4 mt-2">
                    {watch.headshotUrl && (
                      <div>
                        <FormLabel className="mb-2 flex justify-between text-medium-base font-primary">
                          <span>Profile picture width</span>
                          <span>{profilepictureWidth}px</span>
                        </FormLabel>
                        <Slider
                          range
                          value={[profilepictureWidth]}
                          min={30}
                          max={200}
                          onValueChange={([value]) => {
                            const templateClone = cloneDeep(workspaceTemplate);
                            templateClone.design.styles.headshot.style.width =
                              value;
                            handleSetWorkspaceTemplate(templateClone);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="mb-4 mt-2">
                    {watch.headshotUrl && (
                      <div>
                        <FormLabel className="mb-2 flex justify-between text-medium-base font-primary">
                          <span>Profile picture radius</span>
                          <span>{profilepictureradius}px</span>
                        </FormLabel>
                        <Slider
                          range
                          value={[profilepictureradius]}
                          min={0}
                          max={30}
                          onValueChange={([value]) => {
                            const templateClone = cloneDeep(workspaceTemplate);
                            templateClone.design.styles.headshot.style.borderRadius =
                              value;
                            handleSetWorkspaceTemplate(templateClone);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="space-y-4">
                    {personalFields &&
                      personalFields?.map((field) => (
                        <FormField
                          key={field.name}
                          control={form.control}
                          name={field.name}
                          render={({ field: formField }) => (
                            <FormItem>
                              <FormControl>
                                <div className="grid grid-cols-[1fr_44px] gap-2 items-center mb-2">
                                  <Input
                                    readOnly
                                    className="max-w-full "
                                    placeholder={field.label}
                                    {...formField}
                                    type={field.type}
                                    disabled={!field.isVariable}
                                  />
                                  <Switch
                                    onCheckedChange={(e) =>
                                      handleSwitchChange(e, field.name)
                                    }
                                    checked={field.isVariable}
                                    // disabled={!headshot?.id}
                                  />
                                </div>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      ))}
                  </div>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-semibold text-gray-900 mb-4">
                  Business Information
                </h2>

                {/* Company Logo */}
                <div className="mb-6">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">
                    Company Logo
                  </h3>
                  {companyLogoFields &&
                    companyLogoFields?.map((field) => (
                      <FormField
                        key={field.name}
                        control={form.control}
                        name={field.name}
                        render={({ field: formField }) => (
                          <FormItem>
                            <FormControl>
                              {watch.companyLogo ? (
                                <ImageUploadPreview
                                  onChange={() => {
                                    form.setValue(field.name, '');
                                  }}
                                  imageUrl={watch.companyLogo}
                                />
                              ) : (
                                <Cropper
                                  onOk={(files) =>
                                    handleFileUpload([files], formField)
                                  }
                                  allowGif
                                  showAspectbuttons
                                >
                                  Drag & drop or click to upload company logo
                                </Cropper>
                              )}
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    ))}
                  <div className="mb-4 mt-2">
                    {watch.companyLogo && (
                      <FormField
                        control={form.control}
                        name="businessInformation?.logoWidth"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="mb-2 flex justify-between text-medium-base font-primary">
                              <span>Logo width</span>
                              <span>{logoWidth}px</span>
                            </FormLabel>
                            <FormControl>
                              <Slider
                                range
                                value={[logoWidth]}
                                min={50}
                                max={200}
                                onValueChange={([value]) => {
                                  const templateClone =
                                    cloneDeep(workspaceTemplate);
                                  templateClone.design.styles.companyLogo.style.width =
                                    value;
                                  handleSetWorkspaceTemplate(templateClone);
                                }}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    )}
                  </div>
                  <div className="space-y-4">
                    {businessFields &&
                      businessFields?.map((field) => (
                        <FormField
                          key={field.name}
                          control={form.control}
                          name={field.name}
                          render={({ field: formField }) => (
                            <FormItem>
                              <FormControl>
                                <Input
                                  className="max-w-full mb-2"
                                  placeholder={field.label}
                                  {...formField}
                                  type={field.type}
                                  onChange={(e) => {
                                    formField.onChange(e);
                                    form.trigger(field.name);
                                  }}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      ))}
                  </div>
                  <div className="mt-6">
                    <div className="text-sm font-medium text-gray-700 mb-2">
                      Social links
                    </div>
                    {socialFields &&
                      socialFields?.map((field) => (
                        <FormField
                          key={field.name}
                          control={form.control}
                          name={field.name}
                          render={({ field: formField }) => (
                            <FormItem className="mb-4 flex items-center space-x-2">
                              <div className="align-baseline">
                                <Button
                                  type="button"
                                  onClick={() =>
                                    handleRemoveSocialLink(field.name)
                                  }
                                  variant="outlineshortwithoutbackground"
                                  size="iconchange"
                                >
                                  <SquareMinus className="h-5 w-5 text-white" />
                                </Button>
                              </div>
                              <FormControl>
                                <Input
                                  className="max-w-full"
                                  placeholder={field.label}
                                  {...formField}
                                  type={field.type}
                                  onChange={(e) => {
                                    formField.onChange(e);
                                    form.trigger(field.name);
                                  }}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      ))}
                    <Popover
                      open={socialPopover}
                      onOpenChange={setSocialPopover}
                    >
                      <PopoverTrigger asChild>
                        <Button className="flex justify-center items-center gap-2">
                          Add <PlusSquareFilled />{' '}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent>
                        <h3>Select an Option</h3>
                        <div className="grid grid-cols-2 gap-3">
                          {fields?.map((option) => (
                            <div
                              onClick={() => handleSocialSelect(option)}
                              className="flex items-center space-x-2 cursor-pointer"
                            >
                              <div className="h-6 w-6 aspect-square rounded-full overflow-hidden">
                                <img
                                  src={socialImages?.[option.key]}
                                  style={{
                                    display: 'flex',
                                    background: 'black',
                                    height: '100%',
                                    width: '100%',
                                  }}
                                  width="100%"
                                  alt="tik-tok-icon"
                                />
                              </div>
                              <div>{capitalize(option.name)}</div>
                            </div>
                          ))}
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>

                  <div className="mt-6">
                    <div className="text-sm font-medium text-gray-700 mb-2">
                      Footer
                    </div>
                    {footerFields &&
                      footerFields?.map((field) => (
                        <FormField
                          key={field.name}
                          control={form.control}
                          name={field.name}
                          render={({ field: formField }) => (
                            <FormItem>
                              <FormControl>
                                {/* <Textarea
                            className="max-w-full mb-2 resize-y"
                            placeholder={field.label}
                            {...formField}
                            type={field.type}
                          /> */}

                                <CustomEditor
                                  ref={formField.ref}
                                  value={formField.value}
                                  onBlur={formField.onBlur}
                                  onChange={(newContent) =>
                                    formField.onChange(newContent)
                                  }
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      ))}
                  </div>
                </div>
              </section>
            </form>
          </Form>
        </div>
      </div>
    </>
  );
}

export default WorkspaceTemplateInformation;
