import React from 'react';
import { Button } from 'components/ui/button';
import { cn } from 'lib/utils';
import { useRouter } from 'hooks/router';
import { useWorkspaceId } from 'hooks/workspace';

const BlackFridayButton = (props) => {
  const { navigate } = useRouter();
  const { className = '', onClick } = props;
  const { workspaceId } = useWorkspaceId();

  const handleClick = () => {
    if (onClick) {
      onClick();
      return;
    }
    navigate(`/app/${workspaceId}/user/subscriptions`);
  };

  return (
    <div>
      <Button
        className={cn('h-[40px] rounded px-[16px] py-[12px]', className)}
        {...props}
        onClick={handleClick}
      />
    </div>
  );
};

export default BlackFridayButton;
