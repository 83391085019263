import React from 'react';
import { get } from 'lodash';

function SignOff({ style = {}, tdProps = {}, signature = {}, data = {} }) {
  const { font } = get(signature, 'design.styles', {});
  const { signOff } = get(signature, 'design.styles', {});

  const textStyle = {
    fontWeight: 700,
    font,
    fontSize: signOff?.style?.fontSize || 20,
    color: signOff?.style?.color || signature?.design?.primaryBrandColor,
  };

  // const textStyle = {
  //   fontSize: headerFont?.style?.fontSize || fontSize,
  //   lineHeight,
  //   color: signature?.design?.primaryBrandColor,
  // };
  return (
    <td {...tdProps}>
      <h1
        style={{
          ...textStyle,
          ...style,
          margin: 0,
          paddingTop: '10px',
          paddingBottom: '10px',
        }}
      >
        {data?.value}
      </h1>
    </td>
  );
}

export default SignOff;
