import { handleUrlRedirection } from 'common/utils';
import { get } from 'lodash';
import React from 'react';

function CallToActionBanner({ data, style = {}, toProps = {}, design = {} }) {
  const { ctaBannerUrl, ctaBannerImg } = data;
  const width = get(design, 'styles.ctaBanner.style.width', 250);
  const ctaborderRadius = get(
    design,
    'styles.ctaBanner.style.ctaborderRadius',
    0,
  );

  const link = ctaBannerUrl?.utagEnabled
    ? ctaBannerUrl?.shortLink || handleUrlRedirection(ctaBannerUrl?.value)
    : handleUrlRedirection(ctaBannerUrl?.value);

  return (
    <td
      style={{
        ...style,
        textAlign: 'left',
        paddingBottom: '16px',
      }}
      {...toProps}
    >
      <a
        aria-label="link"
        style={{
          color: 'transparent',
          textDecoration: 'none',
        }}
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={ctaBannerImg?.value}
          alt="brand-logo"
          width={width}
          title="ctabanner"
          style={{
            border: '0px',
            lineHeight: '100%',
            outline: 'none',
            textDecoration: 'none',
            display: 'block',
            borderRadius: `${ctaborderRadius}px`,
          }}
        />
      </a>
    </td>
  );
}

export default CallToActionBanner;
