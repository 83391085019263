import React from 'react';
import { useAppContext } from 'AppContext';
import { useRouter } from 'hooks/router';
import { useGetRoleForWorkspace, useUserPermission } from 'hooks/user';
import { FEATURE_KEYS, ROLES, TEXT_IDS } from 'common/constants';
import Button from 'components/Button';

function Branding({ hideBranding = false }) {
  const {
    state: { showBranding, showBrandingText },
    handleSubscriptionModal,
  } = useAppContext();
  const {
    location: { search },
  } = useRouter();
  const params = new URLSearchParams(search);
  const showTemplate = params.get('showTemplate');
  const { isFeaturesAllowed } = useUserPermission();
  const { role } = useGetRoleForWorkspace();

  // eslint-disable-next-line no-console
  // console.log('Branding Page >>', hideBranding);

  // if (
  //   isFeaturesAllowed(FEATURE_KEYS.NO_BRANDING) ||
  //   role === ROLES.TEAMMATE ||
  //   role === ROLES.MANAGER
  // ) {
  //   return <></>;
  // }

  if (hideBranding) {
    return <></>;
  }

  if (!showTemplate) {
    return (
      <table
        style={{
          fontSize: '10px',
          borderTopColor: 'rgb(238, 238, 238)',
          // marginTop: '8px',
        }}
      >
        {showBrandingText && (
          <tr>
            {/* <td>
            <img
              src="/logo32.png"
              height={16}
              width={16}
              alt="logo"
              style={{ display: 'block', margin: 'auto' }}
            />
          </td> */}
            <td id={TEXT_IDS.BRANDING}>
              <p className="mb-2 mt-0">
                Created with
                <a
                  href="https://syncsignature.com/?r=watermark"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: '#7F56D9',
                    fontWeight: '800',

                    textDecoration: 'none',
                    paddingBottom: '2px',
                    paddingLeft: '3px',
                  }}
                >
                  SyncSignature
                </a>
              </p>
            </td>
          </tr>
        )}
        {showBranding && (
          <tr>
            <td colSpan="2" style={{}}>
              <Button
                onClick={() => {
                  handleSubscriptionModal(true);
                }}
              >
                Remove Branding
              </Button>
            </td>
          </tr>
        )}
      </table>
    );
  }
}

export default Branding;
