/* eslint-disable arrow-body-style */
import React from 'react';
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import { useDeleteTeammate } from 'hooks/teammate';
import { useWorkspaceId } from 'hooks/workspace';

const DeleteForm = ({ data, onClose, loading }) => {
  const { workspaceId } = useWorkspaceId();
  const [deleteTeammate] = useDeleteTeammate();
  const onDeleteSubmit = async (id) => {
    await deleteTeammate({
      variables: {
        data: {
          ids: [id],
          workspaceId,
        },
      },
    });
    onClose();
  };
  return (
    <DialogContent
      className="sm:max-w-[425px] rounded-[6px] max-w-sm"
      hideCloseButton
    >
      <div>
        <DialogHeader className="text-left">
          <DialogTitle className="text-lg font-bold text-gray-900 my-0">
            Remove from workspace
          </DialogTitle>
          <DialogDescription className="text-sm text-gray-500">
            Are you sure you want to remove teammate from this workspace ?
          </DialogDescription>
        </DialogHeader>
        <div className="flex w-full">
          <div className="flex justify-end gap-3 w-1/2 ml-auto">
            <Button onClick={() => onClose()} variant="outline" size="sm">
              Cancel
            </Button>
            <Button
              disabled={loading}
              type="submit"
              onClick={() => onDeleteSubmit(data?.id)}
              size="sm"
            >
              Remove
            </Button>
          </div>
        </div>
      </div>
    </DialogContent>
  );
};

export default DeleteForm;
