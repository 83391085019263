/* eslint-disable no-console */
import { useAppContext } from 'AppContext';
import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { FEATURE_KEYS, ROLES_MAP } from 'common/constants';
import {
  GET_USER,
  GET_USER_BY_ID,
  GET_USER_ROLE,
} from 'modules/Auth/graphql/Queries';
import { difference, get, isEmpty } from 'lodash';
import sortBy from 'lodash/sortBy';
import {
  CONFIRM_INVITATION,
  UPDATE_USER,
  UPDATE_USER_WITH_APPSUMO,
  DELETE_USER_MUTATION,
} from 'modules/Auth/graphql/Mutations';
import { useIsUserBrandOwner } from 'hooks/brand';
import { GET_WORKSPACE_OWNER } from 'components/WorkspaceSwitcher/graphql/Mutations';
import { cloneAndRemoveTypename } from 'common/utils';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import { useGetTemplates } from './template';
import { useWorkspaceId } from './workspace';
import { useRouter } from './router';

/* eslint-disable import/prefer-default-export */

export const useFreeTrial = () => {
  const {
    state: { owner },
  } = useAppContext();
  const { navigate } = useRouter();
  const { workspaceId } = useWorkspaceId();
  const isPlanExpired = owner?.isPlanExpired ?? false;
  const isFreeTrialStarted = !!owner?.freeTrialStartedAt;
  const redirectToPlan = () => {
    if (workspaceId && isPlanExpired) {
      navigate(`/app/${workspaceId}/user/subscriptions`);
    }
  };

  return { isPlanExpired, isFreeTrialStarted, redirectToPlan };
};

export const useUserPermission = () => {
  const {
    state: { owner, signature },
  } = useAppContext();
  const currentTemplateId = get(signature, 'template.templateId', null);
  const signatureLimit = get(owner, 'signatureLimit', null);
  const { allowedTemplates = [] } = owner?.activePlan || {
    allowedTemplates: [],
  };
  const isFreeUser = owner?.activePlan?.id === 1;
  const isResellerAgencyUser =
    owner?.activePlan?.id === 4 || owner?.activePlan?.id === 5;
  const featureKeys = owner?.activePlan?.features?.map((f) => f.key);
  const isFeaturesAllowed = (...features) => {
    const featureDifference = difference(features, featureKeys);

    // If 'difference' is empty, all elements of features are included in featureKeys
    const isFeaturesInclueded = isEmpty(featureDifference);
    return isFeaturesInclueded;
  };
  // console.log('object', allowedTemplates);

  const isTemplatesAllowed = useMemo(
    () => allowedTemplates?.includes(currentTemplateId || 1),
    [allowedTemplates, currentTemplateId],
  );

  const showGoogleIntegrationFeature = featureKeys?.includes(
    FEATURE_KEYS.GOOGLE_MARKETPLACE,
  );

  return {
    isFeaturesAllowed,
    signatureLimit,
    allowedTemplates,
    isFreeUser,
    isTemplatesAllowed,
    userRole: owner?.activePlan?.name,
    showGoogleIntegrationFeature,
    isResellerAgencyUser,
  };
};

export const useFeatures = () => {
  const {
    state: { owner, signature, templates: templateData },
    setSignature,
  } = useAppContext();
  // const { signatureId } = useSignatureId();
  const { isUserBrandOwner } = useIsUserBrandOwner();
  const { workspaceId } = useWorkspaceId();

  const defaultTemplate = templateData?.find((t) => t?.templateId === 1);
  const { data: templatesData } = useGetTemplates();
  const { isFeaturesAllowed, isTemplatesAllowed } = useUserPermission();

  const getFeaturesUsedByUser = () => {
    const features = [];
    const hasNonEmptyCTAFields = signature?.fields?.some(
      (f) =>
        ['ctaTitle', 'ctaUrl', 'ctaBannerImg', 'ctaBannerUrl']?.includes(
          f?.name,
        ) && f?.value?.trim() !== '',
    );
    if (hasNonEmptyCTAFields) {
      features.push(FEATURE_KEYS.CALL_TO_ACTION);
    }

    const defaultFonts = 'Arial, Helvetica, sans-serif';
    const fontDefaults = { size: 13, color: '#000000', headerSize: 14 };
    const { font: styleFont, headerFont } = get(signature, 'design.styles', {});
    const { font: defaultFont } = signature?.design || {};

    const hasCustomFont =
      (defaultFont && defaultFont !== defaultFonts) ||
      styleFont?.style?.fontSize !== fontDefaults.size ||
      styleFont?.style?.color !== fontDefaults.color ||
      headerFont?.style?.fontSize !== fontDefaults.headerSize;

    if (hasCustomFont) features.push(FEATURE_KEYS.CUSTOM_FONT);
    return features;
  };

  const isUserUsingProFeatures = () => {
    const features = getFeaturesUsedByUser();
    if (isUserBrandOwner(owner?.id, workspaceId)) {
      return !isFeaturesAllowed(...features) || !isTemplatesAllowed;
    }
    return false;
  };

  const handleRevertToFree = () => {
    const updatedFields = signature?.fields?.map((field) => {
      if (
        field.name === 'ctaTitle' ||
        field.name === 'ctaUrl' ||
        field.name === 'ctaBannerImg' ||
        field.name === 'ctaBannerUrl'
      ) {
        return {
          ...field,
          value: '',
        };
      }
      return field;
    });

    // Default design values
    const defaultFonts = 'Arial, Helvetica, sans-serif';
    const fontDefaults = { size: 12, color: '#000000', headerSize: 13 };

    const updatedDesign = {
      ...signature?.design,
      primaryBrandColor: signature?.design?.primaryBrandColor,
      font: defaultFonts,
      styles: {
        ...signature?.design?.styles,
        font: {
          ...signature?.design?.styles?.font,
          style: {
            ...signature?.design?.styles?.font?.style,
            fontSize: fontDefaults.size,
            color: fontDefaults.color,
          },
        },
        headerFont: {
          ...signature?.design?.styles?.headerFont,
          style: {
            ...signature?.design?.styles?.headerFont?.style,
            fontSize: fontDefaults.headerSize,
          },
        },
      },
    };

    const data = {
      ...signature,
      design: updatedDesign,
      fields: updatedFields,
      template: templatesData?.[0]?.template,
      templateId: templatesData?.[0]?.templateId,
    };
    // const { id, ...restData } = data;
    setSignature(data);
  };

  return { isUserUsingProFeatures, handleRevertToFree };
};

export const useGetUser = () => {
  const {
    state: { currentUser },
    setUserLocalData,
    setCurrentUser,
  } = useAppContext();

  const [getUser] = useLazyQuery(GET_USER, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const prevUser = currentUser;
      const user = get(res, 'me', {});

      user.workspaces = isEmpty(get(user, 'workspaces'))
        ? get(prevUser, 'workspaces', [])
        : user.workspaces;

      setUserLocalData(user);
      setCurrentUser(user);
    },
    onError() {},
  });

  return [getUser];
};

export const useGetUserById = () => {
  const [getUserById, { loading, error, data }] = useLazyQuery(GET_USER_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const userData = get(res, 'getUserById', null);
      if (userData) {
        // setOwner(userData); // Update owner in context
      }
    },
    onError(error) {
      // console.error('Error fetching user by ID:', error);
    },
  });

  return [getUserById, { loading, error, data }];
};

export const useGetWorkspaceOwner = () => {
  const { setOwner } = useAppContext(); // Include setOwner from context

  const [getWorkspaceOwner, { loading, error, data }] = useLazyQuery(
    GET_WORKSPACE_OWNER,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const ownerData = get(res, 'getWorkspaceOwner', null);
        if (ownerData) {
          setOwner(cloneAndRemoveTypename(ownerData));
          // Perform any action if needed with the fetched owner data
        }
      },
      onError: (error) => {
        // console.error('Error fetching workspace owner:', error);
      },
    },
  );

  return [getWorkspaceOwner, { loading, error, data }];
};

export const useCreatePassword = () => {
  const [confirmInvitation, states] = useMutation(CONFIRM_INVITATION, {
    onCompleted: (res) => {},
    onError: (error) => {},
  });

  return [confirmInvitation, states];
};

export const useGetUserRoleForWorkspace = () => {
  const [getUserRoleForWorkspace] = useLazyQuery(GET_USER_ROLE, {
    fetchPolicy: 'network-only',
    onCompleted(res) {},
    onError(error) {},
  });

  return [getUserRoleForWorkspace];
};

export const useGetRoleForWorkspace = () => {
  const {
    state: { currentUser },
  } = useAppContext();
  const { workspaceId } = useWorkspaceId();
  const {
    state: { workspaceTeammates },
  } = useWorkspaceContext();

  const getRole = () => {
    if (currentUser?.id && workspaceId) {
      // Sort workspaceTeammates by userId and roleId (if needed, specify another key for sorting)
      const sortedWorkspaceTeammates = sortBy(workspaceTeammates, ['roleId']);

      // Find the roleId of the currentUser
      const userRoleId = sortedWorkspaceTeammates.find(
        (b) => b.userId === currentUser?.id,
      )?.roleId;

      // Return the corresponding role from ROLES_MAP
      return ROLES_MAP?.[userRoleId] ?? null;
    }

    return null; // Return null if no role is found
  };

  const role = getRole();

  return { role, currentUser };
};

export const useGetUserSignatureDetails = () => {
  const {
    state: { currentUser },
  } = useAppContext();
  const userSignatureLimit = get(currentUser, 'signatureLimit', null);
  const userWorkspaces = currentUser?.workspaces;
  const userAssignedSignature = userWorkspaces?.reduce(
    (acc, ws) => acc + (Number(ws.signatureLimit) || 0),
    0,
  );
  const userUnassignedSignature = userSignatureLimit - userAssignedSignature;
  return {
    userSignatureLimit,
    userAssignedSignature,
    userUnassignedSignature,
    userWorkspaces,
  };
};

export const useUpdateUser = () => {
  const [updateUser, { loading, error }] = useMutation(UPDATE_USER, {
    onCompleted: (res) => {},
    onError: (error) => {},
  });

  return [updateUser, { loading, error }];
};

export const useUpdateUserWithAppSumo = () => {
  const { setCurrentUser } = useAppContext();

  const [updateUserWithAppSumo, { loading, error }] = useMutation(
    UPDATE_USER_WITH_APPSUMO,
    {
      onCompleted(res) {
        const updatedUser = res.updateUserWithAppSumo.data;
        setCurrentUser(updatedUser);
      },
      onError(error) {
        console.error('Error updating user with AppSumo:', error);
      },
    },
  );

  // const updateUser = (userId: string, code: string) => {
  //   return updateUserWithAppSumo({
  //     variables: {
  //       where: { id: userId },
  //       data: { code },
  //     },
  //   });
  // };

  return [updateUserWithAppSumo];
};

export const useDeleteUser = () => {
  const [deleteUser] = useMutation(DELETE_USER_MUTATION, {
    onCompleted: () => {},
    onError: (error) => {
      console.error(error);
    },
  });

  return [deleteUser];
};
