/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState, useRef } from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import { useGetSignature } from 'hooks/signature';
import { clone, get, keyBy, omit, partition, isString } from 'lodash';
import LoaderComponent from 'components/LoaderComponent';
import TeammateSignaturePriview from 'components/TeammateSignaturePriview';
import {
  useGetUserProfileImageUrl,
  useUpsertTeammateSignature,
} from 'hooks/teammate';
import { Detail } from 'assets/svg';
import ImageUploadPreview from 'components/ImageUploadPreview';
import Cropper from 'components/Cropper';
import { Plus, ArrowLeft, Loader2, Type } from 'lucide-react';
import { toast } from 'hooks/use-toast';
import { useRouter } from 'hooks/router';
import { useGetWorkspaceTeammateById } from 'hooks/workspace-teammate';
import { cloneAndRemoveTypename } from 'common/utils';
import { useUpdateWorkspaceTeammateDetails } from 'hooks/workspace-teammate-details';
import { useWorkspaceId } from 'hooks/workspace';
import { useAppContext } from 'AppContext';
import Analytics from 'modules/Analytics';
import HeadshotControlDialog from 'modules/Headshot/HeadshotControlDialog';

export const variableFieldsName = [
  'name',
  'position',
  'department',
  'phone',
  'email',
  'headshotUrl',
];

export const getFieldNameValueMap = (values, fieldName) => {
  const fieldMappings = {
    name: values[fieldName],
    position: values[fieldName],
    department: values[fieldName],
    phone: values[fieldName],
    email: values[fieldName],
    headshotUrl: values[fieldName]?.[0]?.preview || values[fieldName],
  };
  return fieldMappings[fieldName] || '';
};

export const updateFieldValues = (
  fields,
  values,
  variableFieldsMap,
  nonVariableFieldsMap,
) => {
  return fields?.map((field) => {
    if (nonVariableFieldsMap[field.name]) {
      return nonVariableFieldsMap[field.name];
    }
    if (variableFieldsMap[field.name]) {
      const updatedVariableFields = {
        ...variableFieldsMap[field.name],
        value: getFieldNameValueMap(values, field.name),
      };
      if (!variableFieldsMap[field.name]?.isVariable) {
        updatedVariableFields.value = '';
      }
      return updatedVariableFields;
    }
    return field;
  });
};

const TeammateEdit = () => {
  const {
    state: { groups, workspaceTeammates },
  } = useWorkspaceContext();
  const {
    state: { currentUser },
    setSignature: setActualSignature,
  } = useAppContext();
  const headshotDialogRef = useRef(null);

  // Open dialog function

  const [updateWorkspaceTeammateDetails, { loading: submitLoading }] =
    useUpdateWorkspaceTeammateDetails();
  const { workspaceId: currentWorkspaceId } = useWorkspaceId();
  const [upsertTeammateSignature] = useUpsertTeammateSignature();
  const [getWorkspaceTeammateById] = useGetWorkspaceTeammateById();
  const [signature, setSignature] = useState(null);
  const [workspaceTeammate, setWorkspaceTeammate] = useState(null);

  const openHeadshotDialog = () => {
    setActualSignature(signature);
    headshotDialogRef.current?.open();
  };

  const {
    userId = '',
    groupId = '',
    workspaceId = '',
    signatureId = '',
  } = workspaceTeammate || {
    userId: '',
    groupId: '',
    workspaceId: '',
    signatureId: '',
  };
  const [workspaceTeammateDeatils, setWorkspaceTeammateDeatils] =
    useState(null);
  const {
    params: { workspaceTeammateId: workspaceteammateIdParams },
  } = useRouter();

  const workspaceTeammateIdForTeammate = workspaceTeammates?.find(
    (wt) =>
      wt?.workspaceId === currentWorkspaceId && wt?.userId === currentUser?.id,
  )?.id;
  const workspaceTeammateId =
    workspaceteammateIdParams || workspaceTeammateIdForTeammate;

  const { navigate } = useRouter();

  const [getSignature, { loading: signatureLoading }] = useGetSignature();
  const { handleProfileImageUpload } = useGetUserProfileImageUrl();

  const handleBackClick = async () => {
    navigate(`/app/workspace/${workspaceId}/teammates`);
  };
  const [loading, setLoading] = useState(false);

  const addTeammateSchema = z.object({
    name: z.string().min(1, { message: 'Name is required' }),
    email: z.string().email({ message: 'Invalid email address' }),
    position: z.string().min(1, { message: 'Position is required' }),
    phone: z.string().optional(),
    department: z.string().optional(),
    groupId: z.string().optional().nullable(),
    profileImage: z.any().optional(),
  });
  const headshotUrlFromSignature = signature?.fields?.find(
    (field) => field.name === 'headshotUrl',
  )?.value;
  const defaultValues = {
    name: workspaceTeammateDeatils?.name || '',
    email: workspaceTeammateDeatils?.email || '',
    position: workspaceTeammateDeatils?.position || '',
    phone: workspaceTeammateDeatils?.phone || '',
    department: workspaceTeammateDeatils?.department || '',
    groupId: groupId || null,
    profileImage:
      headshotUrlFromSignature ||
      workspaceTeammateDeatils?.profileImage ||
      null,
  };

  const form = useForm({
    resolver: zodResolver(addTeammateSchema),
    values: defaultValues,
  });

  const handleGetWorkspaceTeammateDeatils = async () => {
    const wtres = await getWorkspaceTeammateById({
      variables: {
        where: {
          id: workspaceTeammateId,
        },
      },
    });
    const workspaceTeammate = cloneAndRemoveTypename(
      get(wtres, 'data.getWorkspaceTeammateById.data'),
    );
    const { workspaceTeammateDetails } = workspaceTeammate;

    const defaultValues = {
      name: workspaceTeammateDetails?.name || '',
      email: workspaceTeammateDetails?.email || '',
      position: workspaceTeammateDetails?.position || '',
      phone: workspaceTeammateDetails?.phone || '',
      department: workspaceTeammateDetails?.department || '',
      groupId: groupId || null,
      profileImage:
        headshotUrlFromSignature ||
        workspaceTeammateDetails?.profileImage ||
        null,
    };

    form.reset(defaultValues);
    setWorkspaceTeammate(workspaceTeammate);
    setWorkspaceTeammateDeatils(workspaceTeammate?.workspaceTeammateDetails);
  };

  const handleGetSignature = async (row) => {
    const res = await getSignature({
      variables: {
        where: { signatureId, workspaceId },
      },
    });

    const sig = get(res, 'data.signature');
    setSignature(cloneAndRemoveTypename(sig));
  };

  useEffect(() => {
    if (workspaceTeammateId) {
      handleGetWorkspaceTeammateDeatils();
    }
  }, [workspaceTeammateId]);

  useEffect(() => {
    if (signatureId && workspaceId) {
      handleGetSignature();
    }
  }, [signatureId, workspaceId]);

  const watch = form.watch();

  const watchFields = omit({ ...watch, headshotUrl: watch.profileImage }, [
    'profileImage',
  ]);
  const [variableFields, nonVariableFields] = partition(
    signature?.fields,
    (field) => variableFieldsName.includes(field.name),
  );

  const nonVariableFieldsMap = keyBy(nonVariableFields, 'name');
  const variableFieldsMap = keyBy(variableFields, 'name');

  const updatedFields = updateFieldValues(
    signature?.fields,
    watchFields,
    variableFieldsMap,
    nonVariableFieldsMap,
  );

  const { isDirty, isValid, dirtyFields } = form.formState;
  const handleProfileImageChange = async (file, field) => {
    if (file) {
      setLoading(true);
      field.onChange(file);
      setLoading(false);
    }
  };

  const renderProfileImage = (field) => {
    if (loading) {
      return (
        <div className="flex justify-center items-center w-[98px] h-[98px] rounded-full">
          <Loader2 className="animate-spin mr-2 h-5 w-5" />
        </div>
      );
    }
    return (
      <ImageUploadPreview
        text="Clear"
        onChange={() => {
          form.setValue(field.name, '');
        }}
        file
        imageUrl={watch?.profileImage?.[0]?.preview || watch?.profileImage}
        className="w-[98px] h-[98px] rounded-none"
      />
    );
  };
  const handleUpdateSubmit = async (values, dirtyFields) => {
    const { profileImage, ...restValues } = values;
    let url = profileImage || '';
    if (!isString(profileImage) && dirtyFields.profileImage) {
      url = (await handleProfileImageUpload(profileImage)) || null;
    }
    form.setValue('profileImage', url);
    const newValues = { ...restValues, profileImage: url };
    const res = await updateWorkspaceTeammateDetails({
      variables: {
        data: omit(newValues, ['groupId']),
        where: { id: workspaceTeammateId },
      },
    });
    const data = get(res, 'data.updateWorkspaceTeammateDetails.data', null);
    if (data) {
      form.reset(newValues);
      toast({
        closeicn: 'success',
        description: 'Update successfully',
      });
    }
  };

  const draggerContent = <Plus />;
  return (
    <div className="bg-primary-foreground overflow-auto h-[calc(100vh_-_71px)] px-[24px]">
      <HeadshotControlDialog
        ref={headshotDialogRef}
        onHeadshotSelect={(headshot) => {
          form.setValue('profileImage', headshot, { shouldDirty: true });
          headshotDialogRef.current.close();
        }}
      />
      <div className="bg-white-0 w-full  mt-[20px] relative">
        <div className="p-4 grid grid-cols-2 gap-4">
          <div className="mb-8 items-center ">
            <Form {...form}>
              <h1 className="flex items-center">
                <Button
                  onClick={handleBackClick}
                  variant="text"
                  className="px-0 py-0 mr-2"
                >
                  <ArrowLeft />
                </Button>
                {workspaceTeammateDeatils?.name}
              </h1>
              <form
                onSubmit={form.handleSubmit((f) =>
                  handleUpdateSubmit(f, dirtyFields),
                )}
                className="space-y-3"
              >
                <FormField
                  key="profileImage"
                  control={form.control}
                  name="profileImage"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Profile Image</FormLabel>
                      <div className="flex items-center gap-9">
                        <FormControl>
                          {field.value ? (
                            renderProfileImage(field)
                          ) : (
                            <>
                              <Cropper
                                onOk={(file) =>
                                  handleProfileImageChange([file], field)
                                }
                                showAspectbuttons
                                cropperProps={{ circularCrop: false }}
                                draggerContent={draggerContent}
                                allowGif
                                baseDraggerClassname="rounded-full h-[98px] w-[98px] py-0"
                              />
                            </>
                          )}
                        </FormControl>
                        <Button
                          onClick={openHeadshotDialog}
                          className=""
                          type="button"
                        >
                          Edit
                        </Button>
                      </div>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <table>
                  <tr className="flex gap-3">
                    <td aria-label="col" className="flex flex-col gap-3">
                      <FormField
                        control={form.control}
                        name="name"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Name</FormLabel>
                            <FormControl>
                              <Input placeholder="Enter Name" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Email</FormLabel>
                            <FormControl>
                              <Input
                                disabled
                                placeholder="Enter Email"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="department"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Department</FormLabel>
                            <FormControl>
                              <Input placeholder="Department" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </td>
                    <td aria-label="col" className="flex flex-col gap-3">
                      <FormField
                        control={form.control}
                        name="position"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Position</FormLabel>
                            <FormControl>
                              <Input placeholder="Enter Position" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="phone"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Mobile Number</FormLabel>
                            <FormControl>
                              <Input
                                placeholder="Enter Mobile Number"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="groupId"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Group</FormLabel>
                            <Select
                              {...(groupId ? { value: groupId } : {})}
                              onValueChange={field.onChange}
                              disabled
                            >
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue
                                    className="font-[#00000]"
                                    placeholder="Select Group"
                                  />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {groups
                                  .filter((g) => !!g?.templateId)
                                  .map((group) => (
                                    <SelectItem value={group.id} key={group.id}>
                                      {group.name}
                                    </SelectItem>
                                  ))}
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </td>
                  </tr>
                </table>

                <Button disabled={submitLoading || !isDirty} type="submit">
                  Save Changes
                </Button>
              </form>
            </Form>
          </div>

          <div className="flex justify-center items-center ">
            {signatureLoading ? (
              <LoaderComponent />
            ) : signature ? (
              <div className="">
                <TeammateSignaturePriview
                  isDirty={isDirty}
                  setSignature={setSignature}
                  signature={{ ...signature, fields: updatedFields }}
                />
              </div>
            ) : (
              <div className="border border-solid border-gray-200 rounded-lg p-6 text-center ">
                <div className="text-left font-bold text-lg mb-4">Preview</div>
                <div className="flex flex-col items-center justify-center">
                  <div className="bg-yellow-100 rounded-full p-4 mb-4">
                    <Detail className="h-6 w-6 text-primary" />
                  </div>
                  <div className="font-bold text-gray-800 mb-2">
                    Missing information
                  </div>
                  <div className="text-gray-500">
                    Please assign group to preview the signature
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mb-[20px]">
        {signatureId && <Analytics customSignatureId={signatureId} />}
      </div>
    </div>
  );
};

export default TeammateEdit;
