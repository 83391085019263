/* eslint-disable react/no-array-index-key */
import { handleUrlRedirection } from 'common/utils';
import { get, chunk } from 'lodash';
import React from 'react';

function SocialBanners({
  data,
  style = {},
  toProps = {},
  design = {},
  alignment = 'horizontal',
}) {
  const width = get(design, 'styles.socialBanners.style.width', 100);
  const socialBannersborderRadius = get(
    design,
    'styles.socialBanners.style.socialBannersRadius',
    0,
  );

  // Chunk the data into rows of 4
  const chunkedSocialBanners =
    alignment === 'horizontal' ? chunk(data.value, 4) : [data.value];

  return (
    <td
      style={{
        ...style,

        textAlign: 'left',
        paddingBottom: '8px',
      }}
      {...toProps}
    >
      <table
        cellPadding="0"
        cellSpacing="0"
        style={{
          borderCollapse: 'collapse',
          fontSize: 'inherit',
        }}
      >
        <tbody>
          {alignment === 'horizontal'
            ? chunkedSocialBanners.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map(({ imgUrl, link, platform, shortLink }) => (
                    <td
                      key={platform}
                      style={{ paddingRight: '8px', paddingBottom: '8px' }}
                    >
                      <a
                        aria-label="link"
                        style={{
                          color: 'transparent',
                          textDecoration: 'none',
                        }}
                        href={data?.utagEnabled && shortLink ? shortLink : link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={imgUrl}
                          alt="brand-logo"
                          width={width}
                          title="socialbanners"
                          style={{
                            border: '0px',
                            lineHeight: '100%',
                            outline: 'none',
                            textDecoration: 'none',
                            display: 'block',
                            borderRadius: `${socialBannersborderRadius}px`,
                          }}
                        />
                      </a>
                    </td>
                  ))}
                </tr>
              ))
            : data.value.map(({ imgUrl, link, platform, shortLink }) => (
                <tr key={platform}>
                  <td>
                    <a
                      aria-label="link"
                      style={{
                        color: 'transparent',
                        textDecoration: 'none',
                      }}
                      href={data?.utagEnabled && shortLink ? shortLink : link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={imgUrl}
                        alt="brand-logo"
                        width={width}
                        title="socialbanners"
                        style={{
                          border: '0px',
                          lineHeight: '100%',
                          outline: 'none',
                          textDecoration: 'none',
                          display: 'block',
                          borderRadius: `${socialBannersborderRadius}px`,
                        }}
                      />
                    </a>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </td>
  );
}

export default SocialBanners;
