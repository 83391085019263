import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { DialogHeader, DialogTitle } from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { omit } from 'lodash';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from 'components/ui/form';
import { useGetSignatureList } from 'hooks/signature';
import { z } from 'zod';
import { cloneAndRemoveTypename } from 'common/utils';
import { useWorkspaceId } from 'hooks/workspace';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import { DUMMY_HEADSHOT_URL } from 'common/constants';
import SignatureCard from 'modules/Assets/components/Signatures/SignatureCard';
import { useAppContext } from 'AppContext';
import { useFeatures } from 'hooks/user';
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group'; // Import ShadCN Radio Group
import { Label } from 'components/ui/label';
import { useFilteredTemplates } from 'hooks/template';

const templateSchema = z.object({
  templateName: z.string().min(1, 'Template name is required'),
  creationMethod: z.string().min(1, 'Please select a method'), // Validation for the radio buttons
});

const CreateTemplate = ({ onClose, handleOnClick }) => {
  const {
    state: { groups },
  } = useWorkspaceContext();
  const { handleSubscriptionModal } = useAppContext();

  const { isUserUsingProFeatures } = useFeatures();
  const [getSignatureListing] = useGetSignatureList();
  const [signatures, setSignatures] = useState([]);
  const { workspaceId } = useWorkspaceId();
  const { templates } = useFilteredTemplates();

  const [selectedSignature, setSelectedSignature] = useState(null);
  const [creationMethod, setCreationMethod] = useState('import'); // default selection

  const fetchSignatures = async (skip = 0) => {
    const list = await getSignatureListing({
      variables: {
        pagination: {
          skip,
          limit: 'max',
        },
        where: {
          workspaceId,
        },
      },
    });
    setSignatures(list?.data?.signatures?.data);
    setSelectedSignature(list?.data?.signatures?.data[0]);
  };

  useEffect(() => {
    fetchSignatures();
  }, []);

  const form = useForm({
    resolver: zodResolver(templateSchema),
    defaultValues: {
      templateName: '',
      creationMethod: 'import', // Default value for the radio group
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const handleDataOnSubmit = (data) => {
    if (isUserUsingProFeatures()) {
      handleSubscriptionModal(true);
      return;
    }
    const { templateName: name } = data;
    const formatedSignature = omit(cloneAndRemoveTypename(selectedSignature), [
      'id',
      'name',
      'installation',
    ]);
    const updatedFields = selectedSignature?.fields?.map((field) =>
      field.name === 'headshotUrl'
        ? { ...field, value: field?.value ? DUMMY_HEADSHOT_URL : '' }
        : field,
    );
    if (data.creationMethod === 'import') {
      handleOnClick(
        {
          name,
          signature: { ...formatedSignature, fields: updatedFields },
        },
        'import',
      );
    } else {
      // Build from scratch logic
      handleOnClick(
        {
          name,
          signature: { ...formatedSignature, fields: updatedFields },
        },
        'scratch',
      );
    }
  };

  const handleSignatureSelect = (signature) => {
    setSelectedSignature(signature);
  };

  return (
    <Form {...form}>
      <form
        className="max-w-[inherit] p-0"
        onSubmit={handleSubmit(handleDataOnSubmit)}
      >
        <DialogHeader>
          <DialogTitle>Create Template</DialogTitle>
        </DialogHeader>
        <div>
          <div>
            <FormField
              control={control}
              name="templateName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-lg">Template Name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      id="templateName"
                      placeholder="Enter Template Name"
                      className="h-[22px]"
                    />
                  </FormControl>
                  <FormMessage>{errors.templateName?.message}</FormMessage>
                </FormItem>
              )}
            />
          </div>

          <div className="mt-3">
            <FormField
              control={control}
              name="creationMethod"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Creation Method</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={(val) => field.onChange(val)}
                      value={field.value}
                      className="flex items-center"
                    >
                      <RadioGroupItem
                        value="import"
                        id="import"
                        onClick={() => setCreationMethod('import')}
                      />
                      <Label className="cursor-pointer" htmlFor="import">
                        Import from Signature
                      </Label>

                      <RadioGroupItem
                        value="scratch"
                        id="scratch"
                        onClick={() => setCreationMethod('scratch')}
                      />
                      <Label className="cursor-pointer" htmlFor="scratch">
                        Build from Scratch
                      </Label>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage>{errors.creationMethod?.message}</FormMessage>
                </FormItem>
              )}
            />
          </div>

          {/* Show signature import only when "Import from Signature" is selected */}
          {creationMethod === 'import' && (
            <div>
              <p>Import from Signature</p>
              <div className="flex gap-4  overflow-auto custom-scrollbar">
                <div className="max-w-[855px] ">
                  {signatures.length && (
                    <div>
                      <div className="flex p-2 gap-3">
                        {signatures?.map((signature) => (
                          <SignatureCard
                            signature={signature}
                            key={signature?.id}
                            handleSignatureCardOnClick={handleSignatureSelect}
                            hideAction
                            size="sm"
                            signatureCardClassname={`m-0 ${
                              selectedSignature?.id === signature?.id
                                ? 'shadow-signature'
                                : ''
                            }`}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {creationMethod === 'scratch' && (
            <div>
              <p>Select template</p>
              <div className="flex gap-4  overflow-auto custom-scrollbar">
                <div className=" ">
                  {templates.length && (
                    <div>
                      <div className="flex p-2 gap-3">
                        {templates?.map((signature) => (
                          <SignatureCard
                            signature={signature}
                            key={signature?.id}
                            handleSignatureCardOnClick={handleSignatureSelect}
                            hideAction
                            size="sm"
                            signatureCardClassname={`m-0 ${
                              selectedSignature?.id === signature?.id
                                ? 'shadow-signature'
                                : ''
                            }`}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-end gap-3 pt-4">
          <Button
            type="button"
            className="w-[max-content]"
            onClick={() => onClose()}
            variant="outline"
          >
            Cancel
          </Button>
          <Button type="submit">Submit</Button>
        </div>
      </form>
    </Form>
  );
};

export default CreateTemplate;
