/* eslint-disable import/prefer-default-export */

import { useLazyQuery, useQuery } from '@apollo/client';
import { cloneAndRemoveTypename } from 'common/utils';
import { v4 as uuid } from 'uuid';
import { GET_TEMPLATES } from 'components/TemplatesModel/graphql/Queries';
import { TEMPLATE_MODELS } from 'components/TemplatesModel/utils/constants';
import { useAppContext } from 'AppContext';
import { useMemo } from 'react';

export const useTemplateData = () => {
  const { setTemplates } = useAppContext();

  const [getTemplates, states] = useLazyQuery(GET_TEMPLATES, {
    variables: {
      pagination: {
        skip: 0, // Start from the beginning
        limit: 'max', // Default to fetching all items
      },
    },

    fetchPolicy: 'network-only',
    onCompleted(data) {
      setTemplates(cloneAndRemoveTypename(data?.templates?.data));
    },
    onError() {
      // Handle error (optional)
    },
  });

  // This will return the function to trigger the query and the data/loader state
  return [
    getTemplates, // This is the function to trigger the lazy query
    states,
  ];
};

export const useGetTemplates = () => {
  const {
    state: { templates },
  } = useAppContext();

  // Memoize TEMPLATE_MODELS_CLONE to prevent re-creating it on every render
  const TEMPLATE_MODELS_CLONE = useMemo(
    () =>
      [...TEMPLATE_MODELS].map((t) => ({
        ...t,
        id: uuid(),
      })),
    [],
  ); // Empty array ensures that uuid() is only called once when the component mounts

  useMemo(() => {
    templates?.forEach((template) => {
      // Loop through all the indices where the templateId matches
      TEMPLATE_MODELS_CLONE.forEach((model, index) => {
        if (model.template.templateId === template.templateId) {
          TEMPLATE_MODELS_CLONE[index].template = template;
          TEMPLATE_MODELS_CLONE[index].templateId = template.id;
        }
      });
    });
  }, [templates, TEMPLATE_MODELS_CLONE]);

  return { data: TEMPLATE_MODELS_CLONE, loading: false };
};

export const TEMPLATE_ORDER = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 13, 24, 14, 15, 16, 17, 18, 19, 20, 21, 22, 26,
  27, 25, 30, 32, 33,
];

export const useFilteredTemplates = () => {
  // Fetch template data using useGetTemplates hook
  const { data, loading } = useGetTemplates();

  const filterAndSortTemplates = (data) => {
    if (!data) return [];

    const templateIdToIndexMap = TEMPLATE_ORDER.reduce(
      (acc, templateId, index) => {
        acc[templateId] = index;
        return acc;
      },
      {},
    );

    const filteredAndSortedData = data
      .filter((template) =>
        TEMPLATE_ORDER.includes(template?.template?.templateId),
      )
      .sort((a, b) => {
        const indexA = templateIdToIndexMap[a.template.templateId];
        const indexB = templateIdToIndexMap[b.template.templateId];
        return indexA - indexB;
      });

    return filteredAndSortedData;
  };

  // Return loading status and filtered data
  return {
    templates: filterAndSortTemplates(data),
    loading,
  };
};
