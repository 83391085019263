import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { AlertTriangle } from 'lucide-react';
import { Dialog, DialogContent } from 'components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from 'components/ui/form';
import ImageUploadPreview from 'components/ImageUploadPreview';
import Cropper from 'components/Cropper';
import { Alert, AlertDescription } from 'components/ui/alert';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { Button } from 'components/ui/button';
import { Slider } from 'components/ui/slider';
import { useGetCtaBannerSignedUrl } from 'hooks/cta';
import { IMAGE_TYPE } from 'common/constants';
import { fileUpload, getLiveUrl, resizeImage } from 'common/utils';

const preDefinedBanners = [
  {
    id: 1,
    name: 'Product Hunt #1',
    image:
      'https://intimg.mysignature.io/banner_gallery/5faeef1541145ab7688d5726ebed20fc.png',
  },
  {
    id: 2,
    name: 'Product Hunt Join',
    image:
      'https://intimg.mysignature.io/banner_gallery/7aec84cdd34e2c1fab581e472b3b6994.png',
  },
  {
    id: 3,
    name: 'Trustpilot Review',
    image:
      'https://intimg.mysignature.io/banner_gallery/2b83bf4b6b593f89d21f1e37c8ac5a16.png',
  },
  {
    id: 4,
    name: 'Trustpilot Review',
    image:
      'https://intimg.mysignature.io/banner_gallery/755b98005a7ac09d47974a23534aec78.png',
  },
  {
    id: 5,
    name: 'Trustpilot Review',
    image:
      'https://intimg.mysignature.io/banner_gallery/694191a9c3bf9a3f2395fb64320e4236.png',
  },
];

const CtaBannerDialog = forwardRef(
  ({ signature, form, watch, ctaBannerImgFields }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedBanner, setSelectedBanner] = useState(null);
    const [aspectRatio, setAspectRatio] = useState(1.3);

    useImperativeHandle(ref, () => ({
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
    }));

    const [getCtaBannerSignUrl] = useGetCtaBannerSignedUrl();

    const generateSignUrl = async (variables) => {
      const res = await getCtaBannerSignUrl({
        variables: {
          data: {
            ...variables,
          },
        },
      });
      return res?.data?.getCtaBannerUploadSignedUrl;
    };

    const handleFileUpload = async (files, field) => {
      const file = files?.[0];
      if (file) {
        const isGif = file.type === IMAGE_TYPE.gif;
        let uploadFile = file;
        if (!isGif) {
          uploadFile = await resizeImage(file, 200);
        }
        const { name } = uploadFile || {};
        const ext = name.substring(name.lastIndexOf('.') + 1);
        const filename = name.split('.').slice(0, -1).join('.');
        const newFilename = `${filename}.${ext}`;
        const res = await generateSignUrl({
          fileName: newFilename,
          id: signature?.id,
          type: 'signature',
        });
        if (res) {
          const { signedUrl, key } = res;

          try {
            await fileUpload(signedUrl, file);
            const liveUrl = getLiveUrl(key);
            form.setValue(field.name, liveUrl);
            form.setValue('ctaBannerUrl', 'https://yourwebsite.com');
          } catch (error) {
            return false;
          }
        }
      }
    };

    const handleSetSelectedBanner = (banner) => {
      setSelectedBanner(banner?.id);
      form.setValue('ctaBannerImg', banner?.image);
      form.setValue('ctaBannerUrl', 'https://yourwebsite.com');
    };

    return (
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="max-w-5xl p-8">
          <h2 className="text-lg font-semibold mb-4">
            Choose your banner from the gallery
          </h2>
          <div className="grid grid-cols-2 gap-6">
            <div className="space-y-4">
              {/* <Select>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="All banners" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">All banners</SelectItem>
                  <SelectItem value="product-hunt">Product Hunt</SelectItem>
                  <SelectItem value="trustpilot">Trustpilot</SelectItem>
                </SelectContent>
              </Select> */}
              <div className="space-y-4 p-4 overflow-y-auto h-[calc(100vh-400px)]">
                {preDefinedBanners.map((banner) => (
                  <img
                    key={banner.id}
                    src={banner.image}
                    alt={banner.name}
                    className={`cursor-pointer rounded-lg border-2 w-full h-auto ${
                      selectedBanner === banner.id
                        ? 'shadow-signature'
                        : 'border-transparent'
                    }`}
                    onClick={() => handleSetSelectedBanner(banner)}
                  />
                ))}
              </div>
            </div>
            <div className="space-y-4 pt-4 px-4 flex flex-col justify-between">
              <Form {...form}>
                {ctaBannerImgFields?.map((field) => (
                  <FormField
                    key={field.name}
                    control={form.control}
                    name={field.name}
                    render={({ field: formField }) => (
                      <FormItem>
                        <FormControl>
                          {watch.ctaBannerImg ? (
                            <ImageUploadPreview
                              className="rounded-lg mb-4 h-[300px]"
                              previewImageClassName="max-w-[80%]"
                              onChange={() => {
                                form.setValue(field.name, '');
                              }}
                              imageUrl={watch.ctaBannerImg}
                            />
                          ) : (
                            <Cropper
                              onOk={(file) => {
                                handleFileUpload([file], formField);
                              }}
                              showAspectbuttons
                              allowGif
                              cropperProps={{ aspect: aspectRatio }}
                              baseDraggerClassname="mb-4 h-[300px]"
                            />
                          )}
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ))}
              </Form>
              <div className="flex justify-end gap-2 mt-6">
                <Button variant="outline" className="w-[max-content]" onClick={() => setIsOpen(false)}>
                  Cancel
                </Button>
                <Button onClick={() => setIsOpen(false)}>Apply</Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  },
);

export default CtaBannerDialog;
