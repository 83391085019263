/* eslint-disable arrow-body-style */
import AiHeadshotButton from 'components/AiHeadshotButton';
import BlackFridayButton from 'components/BlackFridayButton';
import ProfileAvatar from 'components/ProfileAvatar';
import { Zap } from 'lucide-react';
import { useRedirectToWorkspace } from 'hooks/workspace';
import React from 'react';
import { LogoFullIcon } from 'assets/svg';
import HeadwayWidget from 'components/HeadwayWidget';
import { isMatchingURL } from 'common/utils';
import { useGetBrandDetails, useFeatureChecks } from 'hooks/brand';
import Logo from 'components/Logo';
import { useUserPermission } from 'hooks/user';
import Helper from 'components/Helper';

const Header = () => {
  const { redirectToWorkspace } = useRedirectToWorkspace();
  const { isAIHeadshotsAllowed } = useFeatureChecks();
  const { isResellerAgencyUser } = useUserPermission();

  const { logoUrl } = useGetBrandDetails();
  return (
    <nav className="bg-[#FFFBFE] flex justify-between h-[70px]  border-solid border-0 border-b-1 border-primary">
      <div className="flex items-center pl-[16px]">
        <Logo onClick={redirectToWorkspace} />
        {isMatchingURL() && <HeadwayWidget />}
      </div>

      <div className="flex items-center pr-[16px]">
        {!isResellerAgencyUser && (
          <BlackFridayButton className="mr-4 flex justify-center bg-gradient-to-r from-[#8557E9] via-[#BC57E6] to-[#F357E3]">
            Black Friday Deal
            <Zap size={16} className="ml-[6px] fill-white-0" />
          </BlackFridayButton>
        )}

        <ProfileAvatar />
      </div>
    </nav>
  );
};

export default Header;
