import { get } from 'lodash';
import React from 'react';
import { getFieldData, getLiveUrl } from 'common/utils';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Phone from './components/Phone';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';
import CompanyPhone from './components/CompanyPhone';
import CompanyEmail from './components/CompanyEmail';

function TemplateStyleFour({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const section1 =
    !getFieldData(fields, 'headshotUrl') &&
    !getFieldData(fields, 'name') &&
    !getFieldData(fields, 'position') &&
    !getFieldData(fields, 'department');

  const section3 =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyEmail') &&
    !getFieldData(fields, 'companyPhone') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website');

  const allUrlsAreNull =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'companyLogo');

  const ctasection =
    !getFieldData(fields, 'ctaUrl') &&
    !getFieldData(fields, 'ctaTitle') &&
    !getFieldData(fields, 'ctaBannerImg') &&
    !getFieldData(fields, 'ctaBannerUrl');

  const separatorWidth = get(design, 'styles.separator.style.width', 1);
  const separatorColor = get(
    design,
    'styles.separator.style.color',
    design?.primaryBrandColor,
  );
  const separatorType = get(design, 'styles.separator.style.type', 'solid');

  return (
    <table
      cellPadding="0"
      cellSpacing="0"
      border="0"
      style={{
        margin: '0 !important',
        padding: '0 !important',
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      {getFieldData(fields, 'signOff') && (
        <tr>
          {SignOff({
            signature,
            data: getFieldData(fields, 'signOff'),
            style: {
              fontWeight: 700,
              fontFamily: 'inherit',
              paddingBottom: '2px',
            },
          })}
        </tr>
      )}
      <tr>
        <td>
          <table
            ref={mainContentRef}
            cellPadding="0"
            cellSpacing="0"
            border="0"
            role="presentation"
            style={{
              fontSize: 'inherit',
              paddingBottom: '16px',
            }}
          >
            <tr>
              <td
                style={{
                  paddingRight: '14px',
                  // borderBottom: !allUrlsAreNull
                  //   ? `${separatorWidth}px ${separatorType} ${separatorColor}`
                  //   : '',
                  paddingBottom: '8px',
                  verticalAlign: 'top',
                }}
              >
                <table>
                  <tr>
                    <td style={{ fontSize: '0px', lineHeight: '0px' }}>
                      <table
                        cellPadding="0"
                        cellSpacing="0"
                        border="0"
                        role="presentation"
                        style={{
                          fontSize: 'inherit',
                        }}
                      >
                        {getFieldData(fields, 'headshotUrl') && (
                          <tr>
                            {HeadshotImage({
                              data: getFieldData(fields, 'headshotUrl'),
                              design,
                              style: {
                                textAlign: 'center',
                                justifyContent: 'center',
                                paddingBottom: '8px',
                              },
                            })}
                          </tr>
                        )}
                      </table>
                    </td>
                    <td style={{ fontStyle: 'normal' }}>
                      <table>
                        {getFieldData(fields, 'name') && (
                          <tr>
                            {Name({
                              signature,
                              data: getFieldData(fields, 'name'),
                              style: {
                                color: design?.primaryBrandColor,
                                fontWeight: 700,
                                paddingBottom: '2px',
                              },
                            })}
                          </tr>
                        )}
                        {getFieldData(fields, 'position') && (
                          <tr>
                            {Position({
                              signature,
                              data: getFieldData(fields, 'position'),
                              style: {
                                fontFamily: 'inherit',
                                paddingBottom: '2px',
                              },
                            })}
                          </tr>
                        )}
                        {getFieldData(fields, 'department') && (
                          <tr>
                            {Department({
                              signature,
                              data: getFieldData(fields, 'department'),
                              style: {
                                fontFamily: 'inherit',
                                paddingBottom: '2px',
                              },
                            })}
                          </tr>
                        )}
                        {getFieldData(fields, 'companyName') && (
                          <tr>
                            {CompanyName({
                              signature,
                              data: getFieldData(fields, 'companyName'),
                              style: {
                                fontFamily: 'inherit',
                                paddingBottom: '2px',
                              },
                            })}
                          </tr>
                        )}
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
              {(getFieldData(fields, 'phone') ||
                getFieldData(fields, 'email') ||
                getFieldData(fields, 'website') ||
                getFieldData(fields, 'companyAddress')) && (
                <td
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 0,
                    borderLeft: !section1
                      ? `${separatorWidth}px ${separatorType} ${separatorColor}`
                      : 'none',
                    paddingLeft: '14px',
                    paddingBottom: '2px',
                  }}
                >
                  <table>
                    {getFieldData(fields, 'phone') && (
                      <tr>
                        {Phone({
                          signature,
                          showAwsIcons,
                          design,
                          data: getFieldData(fields, 'phone'),
                          style: {
                            fontFamily: 'inherit',
                          },
                        })}
                      </tr>
                    )}
                    {getFieldData(fields, 'companyPhone') && (
                      <tr>
                        {CompanyPhone({
                          signature,
                          showAwsIcons,
                          design,
                          data: getFieldData(fields, 'companyPhone'),
                          style: { paddingRight: '8px' },
                        })}
                      </tr>
                    )}
                    {getFieldData(fields, 'email') && (
                      <tr>
                        {Email({
                          signature,
                          showAwsIcons,
                          design,
                          data: getFieldData(fields, 'email'),
                          style: {
                            fontFamily: 'inherit',
                          },
                        })}
                      </tr>
                    )}
                    {getFieldData(fields, 'companyEmail') && (
                      <tr>
                        {CompanyEmail({
                          signature,
                          showAwsIcons,
                          design,
                          data: getFieldData(fields, 'companyEmail'),
                        })}
                      </tr>
                    )}
                    {getFieldData(fields, 'website') && (
                      <tr>
                        {Website({
                          signature,
                          showAwsIcons,
                          design,
                          data: getFieldData(fields, 'website'),
                          style: {
                            fontFamily: 'inherit',
                          },
                        })}
                      </tr>
                    )}
                    {getFieldData(fields, 'companyAddress') && (
                      <tr>
                        {CompanyAddress({
                          signature,
                          showAwsIcons,
                          design,
                          data: getFieldData(fields, 'companyAddress'),
                          style: {
                            fontFamily: 'inherit',
                          },
                        })}
                      </tr>
                    )}
                  </table>
                </td>
              )}
            </tr>
            {!allUrlsAreNull && (
              <tr>
                <td
                  colSpan={2}
                  style={{
                    borderBottom: !allUrlsAreNull
                      ? `${separatorWidth}px ${separatorType} ${separatorColor}`
                      : '',
                    paddingTop: '8px',
                  }}
                />
              </tr>
            )}

            <tr>
              {getFieldData(fields, 'companyLogo') && (
                <td
                  style={{
                    borderBottom:
                      !allUrlsAreNull && !section3
                        ? `${separatorWidth}px ${separatorType} ${separatorColor}`
                        : '',

                    paddingTop: '8px',
                  }}
                >
                  {getFieldData(fields, 'companyLogo') && (
                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      border="0"
                      role="presentation"
                      style={{
                        width: '100%',
                      }}
                    >
                      <tr>
                        {getFieldData(fields, 'companyLogo') && (
                          <CompanyLogo
                            data={getFieldData(fields, 'companyLogo')}
                            design={design}
                            style={{
                              textAlign: 'left',
                            }}
                          />
                        )}
                      </tr>
                    </table>
                  )}
                </td>
              )}
              {!!socialFields?.length && (
                <td
                  style={{
                    borderBottom:
                      !allUrlsAreNull && !section3
                        ? `${separatorWidth}px ${separatorType} ${separatorColor}`
                        : '',

                    paddingTop: '8px',
                    textAlign: 'right',
                  }}
                >
                  <table style={{ width: '100%' }}>
                    <tr>
                      {!!socialFields?.length && (
                        <SocialLinks
                          signature={signature}
                          showAwsIcons={showAwsIcons}
                          data={socialFields}
                          design={design}
                          tdProps={{
                            align: 'right',
                            valign: 'center',
                          }}
                          style={{}}
                        />
                      )}
                    </tr>
                  </table>
                </td>
              )}
            </tr>
          </table>
        </td>
      </tr>

      {getFieldData(fields, 'ctaUrl') && getFieldData(fields, 'ctaTitle') && (
        <tr>
          {CallToAction({
            signature,
            showAwsIcons,
            design,
            data: {
              ctaUrl: getFieldData(fields, 'ctaUrl'),
              ctaTitle: getFieldData(fields, 'ctaTitle'),
            },
            style: { paddingBottom: '16px' },
            tableprops: {},
          })}
        </tr>
      )}
      {getFieldData(fields, 'ctaBannerImg') &&
        getFieldData(fields, 'ctaBannerUrl') && (
          <tr>
            {CallToActionBanner({
              data: {
                ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
                ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
              },
              design,
              style: { textAlign: 'left', paddingBottom: '16px' },
            })}
          </tr>
        )}
      {!!getFieldData(fields, 'socialBanners')?.value?.length && (
        <tr>
          {SocialBanners({
            data: getFieldData(fields, 'socialBanners'),
            design,
            style: {
              textAlign: 'left',
            },
          })}
        </tr>
      )}
      {getFieldData(fields, 'footer') && (
        <tr>
          {Footer({
            data: getFieldData(fields, 'footer'),
            design,
            style: {
              textAlign: 'left',
            },
          })}
        </tr>
      )}
      <tr>
        <Branding hideBranding={hideBranding} />
      </tr>
    </table>
  );
}

export default TemplateStyleFour;
