import { socialImages } from 'assets/social-images';

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';

/* Date and time */
export const defaultDateFormat = 'DD-MM-YYYY';

export const AI_MODELS = {
  v1: 'photoaistudio-v1',
  v2: 'photoaistudio-v3',
};

export const AI_MODELS_NAME_MAP = {
  'photoaistudio-v1': 'photoaistudio-v1',
  'photoaistudio-v2': 'photoaistudio-v3',
  'photoaistudio-v3': 'photoaistudio-v3',
  photoaistudio: 'photoaistudio-v3',
};

export const CHECKOUT_TYPES = {
  CHECKOUT_LINK: 'checkout_link',
  OVERLAY_LINK: 'overlay_link',
};

export const TEXT_IDS = {
  PHONE: 'phone-text-id',
  PHONEWORD: 'phonename-text-id',
  EMAIL: 'email-text-id',
  EMAILWORD: 'emailname-text-id',
  COMPANYPHONE: 'company-phone-text-id',
  COMPANYEMAIL: 'company-email-text-id',
  WEBSITE: 'website-text-id',
  WEBSITEWORD: 'websitename-text-id',
  LINK: 'link-text-id',
  LOCATION: 'location-text-id',
  COMPANY: 'company-text-id',
  DEPARTMENT: 'department-text-id',
  ADDRESS: 'address-text-id',
  ADDRESSWORD: 'addressname-text-id',
  BRANDING: 'branding-text-id',
  POSITION: 'position-text-id',
  NAME: 'name-text-id',
  SOCIALLINKWORD: 'socailname-text-id',
};

export const TEXT_IDS_ARRAY = [
  'PHONE',
  'EMAIL',
  'WEBSITE',
  'LINK',
  'LOCATION',
  'COMPANY',
  'DEPARTMENT',
  'ADDRESS',
  'BRANDING',
  'POSITION',
  'COMPANYPHONE',
  'COMPANYEMAIL',
  'NAME',
  'SOCIALLINKWORD',
];

export const TEXT_IDS_ARRAY_PRIMARY = ['NAME'];

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|http?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL:
    /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
  USERNAME: /^@|^#/i,
};

export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200,
};
export const JOURNEYS = {
  AI_HEADSHOT: 'ai-headshot',
};
export const SECONDS = 0;
export const MINUTES = 2;

export const DEFAULT_DESIGN_STYLE = {
  icon: {
    type: 'rounded',
    style: {
      size: 20,
    },
  },
  socialIcon: {
    type: 'rounded',
    style: {
      size: 20,
    },
  },
  font: {
    style: {
      fontSize: 13,
      color: '#000000',
      lineHeight: 1.09,
    },
  },
  headerFont: {
    style: {
      fontSize: 14,
    },
  },
  companyLogo: {
    style: {
      width: 80,
    },
  },
  cta: {
    style: {
      width: 100,
      borderRadius: 4,
    },
  },
  ctaBanner: {
    style: {
      width: 250,
    },
  },
  headshot: {
    style: {
      width: 98,
    },
  },
  footer: {
    style: {
      width: 100,
      color: '#000000',
    },
  },
  socialBanners: {
    style: {
      width: 100,
    },
  },
  signOff: {
    style: {
      fontSize: 20,
    },
  },
  separator: {
    style: {
      width: 1,
      type: 'solid',
    },
  },
};

export const ROLES = {
  ALL: 'ALL',
  OWNER: 'OWNER',
  MANAGER: 'MANAGER',
  TEAMMATE: 'TEAMMATE',
  EDITOR: 'EDITOR',
};

export const ROLES_MAP = {
  1: 'OWNER',
  2: 'MANAGER',
  3: 'TEAMMATE',
  4: 'EDITOR',
};

export const PLAN_ID_MAP = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
  11: 11,
  12: 12,
  13: 13,
};

export const PLAN_ID_ARRAY = Object.values(PLAN_ID_MAP);

export const appUrl =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://app.syncsignature.com'
    : 'https://app.dev.syncsignature.com';

export const BRAND_ACCESS = {
  REGISTRATION_ALLOWED: 'REGISTRATION_ALLOWED',
  PLANS_ALLOWED: 'PLANS_ALLOWED',
  RESELLER_ALLOWED: 'RESELLER_ALLOWED',
  PUBLICHEADSHOT_ALLOWED: 'PUBLICHEADSHOT_ALLOWED',
  AIHEADSHOTS_ALLOWED: 'AIHEADSHOTS_ALLOWED',
};

export const DUMMY_HEADSHOT_URL =
  'https://static.dev.sendsig.com/asset/dummy-profile-pic-modified.png';

export const SOCIAL_LINKS = [
  {
    label: 'Facebook',
    option: 'FACEBOOK',
    key: 'facebook-circle',
    name: 'facebook',
  },
  {
    label: 'Instagram',
    option: 'INSTAGRAM',
    key: 'instagram-circle',
    name: 'instagram',
  },
  {
    label: 'Linkedin',
    option: 'LINKEDIN',
    key: 'linkdin-circle',
    name: 'linkedin',
  },
  {
    label: 'Twitter',
    option: 'TWITTER',
    key: 'x-circle',
    name: 'twitter',
  },
  {
    label: 'Youtube',
    option: 'YOUTUBE',
    key: 'youtube-circle',
    name: 'youtube',
  },
  {
    label: 'Tik tok',
    option: 'TIKTOK',
    key: 'tik-tok-circle',
    name: 'tiktok',
  },
  {
    label: 'Pinterest',
    option: 'PINTEREST',
    key: 'pinterest-circle',
    name: 'pinterest',
  },
  {
    label: 'Vimeo',
    option: 'VIMEO',
    key: 'vimeo-circle',
    name: 'vimeo',
  },
  {
    label: 'GitHub',
    option: 'GITHUB',
    key: 'github-circle',
    name: 'github',
  },
  {
    label: 'Medium',
    option: 'MEDIUM',
    key: 'medium-circle',
    name: 'medium',
  },
  {
    label: 'Podcast',
    option: 'PODCAST',
    key: 'podcast-circle',
    name: 'podcast',
  },
  {
    label: 'Skype',
    option: 'SKYPE',
    key: 'skype-circle',
    name: 'skype',
  },
  {
    label: 'Tripadvisor',
    option: 'TRIPADVISOR',
    key: 'tripadvisor-circle',
    name: 'tripadvisor',
  },
  {
    label: 'Patreon',
    option: 'PATREON',
    key: 'patreon-circle',
    name: 'patreon',
  },
  {
    label: 'Flicker',
    option: 'FLICKER',
    key: 'flickr-circle',
    name: 'flicker',
  },
  {
    label: 'IMDb',
    option: 'IMDB',
    key: 'IMDB-circle',
    name: 'imdb',
  },
  {
    label: 'Rarible',
    option: 'RARIBLE',
    key: 'rarible-circle',
    name: 'rarible',
  },
  {
    label: 'Nextdoor',
    option: 'NEXTDOOR',
    key: 'nextdoor-circle',
    name: 'nextdoor',
  },
  {
    label: 'Behance',
    option: 'BEHANCE',
    key: 'behance-circle',
    name: 'behance',
  },
  {
    label: 'Angi',
    option: 'ANGI',
    key: 'angi-circle',
    name: 'angi',
  },
  {
    label: 'Linktree',
    option: 'LINKTREE',
    key: 'linktree-circle',
    name: 'linktree',
  },
  {
    label: 'Whatsapp',
    option: 'WHATSAPP',
    key: 'whatsapp-circle',
    name: 'whatsapp',
  },
];

export const FIELDS_WITH_ICON = [
  ...SOCIAL_LINKS.map((s) => s.name),
  'phone',
  'email',
  'website',
  'companyAddress',
];

export const DISPLAY_MODE = {
  DARK: 'DARK',
  LIGHT: 'LIGHT',
};

export const HEADSHOT_STYLE = {
  STYLE_1: 'STYLE_1',
  STYLE_2: 'STYLE_2',
  STYLE_3: 'STYLE_3',
  STYLE_4: 'STYLE_4',
  STYLE_5: 'STYLE_5',
  STYLE_6: 'STYLE_6',
};

export const SIGNATURE_STYLE = {
  TEMPLATE_1: 'TEMPLATE_1',
  TEMPALTE_2: 'TEMPALTE_2',
  TEMPLATE_3: 'TEMPLATE_3',
  TEMPLATE_4: 'TEMPLATE_4',
  TEMPLATE_5: 'TEMPLATE_5',
  TEMPLATE_6: 'TEMPLATE_6',
};

export const PLANS = [
  {
    name: 'SOLO',
    price: '29',
    features: [
      { label: 'upto 5 Signatures' },
      { label: 'No SyncSignature branding' },
      { label: 'Copy/Paste email signature' },
      { label: 'Download HTML' },
      { label: 'PRO Templates' },
      { label: 'CTA/Banner' },
      { label: 'Signature analytics' },
    ],
  },
  {
    name: 'TEAM',
    price: '69',
    features: [
      { label: 'upto 40 Signatures / members' },
      { label: 'No SyncSignature branding' },
      { label: 'Copy/Paste email signature' },
      { label: 'Download HTML' },
      { label: 'PRO Templates' },
      { label: 'CTA/Banner' },
      { label: 'Signature analytics' },
      { label: 'Clone Signature' },
      { label: 'Share via email' },
      { label: 'Companywide Signature analytics' },
      { label: 'Workspace' },
    ],
  },
  {
    name: 'AGENCY',
    price: '119',
    features: [
      { label: 'upto 100 Signatures' },
      { label: 'No SyncSignature branding' },
      { label: 'Copy/Paste email signature' },
      { label: 'Download HTML' },
      { label: 'PRO Templates' },
      { label: 'CTA/Banner' },
      { label: 'Signature analytics' },
      { label: 'Clone Signature' },
      { label: 'Share via email' },
      { label: 'Companywide Signature analytics' },
      { label: 'Multiple Workspaces' },
      { label: 'White label' },
    ],
  },
];

export const PRIMARY_COLOR = '#7f56d9';
export const FONT_FAMILY = 'Arial, Helvetica, sans-serif';
export const EMAIL_PLACEHOLDER = 'john.doe@example.com';

export const returnIcon = (name) => socialImages[name];

export const contactIconLinks = {
  email: returnIcon('email-circle'),
  website: returnIcon('link-circle'),
  address: returnIcon('location-circle'),
  phone: returnIcon('phone-circle'),
};

export const DUMMY_DATA = {
  personalInformation: {
    department: 'Marketing',
    name: 'Olivia Bolton',
    position: 'Marketing Manger',
  },
  businessInformation: {
    companyName: 'YourWebsite',
  },
  contactInformation: {
    phone: ' 212-232-3233',
    email: 'dummyemail@dummy.com',
    website: 'https://yourwebsite.com',
  },
  socialLinks: {
    1: {
      INSTAGRAM: 'https://www.instagram.com/#',
    },
    2: {
      LINKEDIN: 'https://www.linkedin.com/company/#',
    },
    3: {
      TWITTER: 'https://twitter.com/#',
    },
  },
};

export const ALLOWED_IMAGE_SIZE = 15728640;

export const OVERVIEW = [
  {
    label: 'Clicks',
    count: 10,
  },
  {
    label: 'Browser',
    count: 20,
  },
  {
    label: 'Device',
    count: 30,
  },
  {
    label: 'Region',
    count: 40,
  },
  {
    label: 'OS',
    count: 50,
  },
];

export const LOCATION_DATA = [
  { country: 'cn', value: 1389618778 }, // china
  { country: 'in', value: 1311559204 }, // india
  { country: 'us', value: 331883986 }, // united states
  { country: 'id', value: 264935824 }, // indonesia
  { country: 'pk', value: 210797836 }, // pakistan
  { country: 'br', value: 210301591 }, // brazil
  { country: 'ng', value: 208679114 }, // nigeria
  { country: 'bd', value: 161062905 }, // bangladesh
  { country: 'ru', value: 141944641 }, // russia
  { country: 'mx', value: 127318112 }, // mexico
];
export const SIMILAR_TO_WHITE = [
  '#fbf9fe',
  '#f9f7fc',
  '#ffffff',
  '#fafafa',
  '#faf8fd',
  '#fffff0',
  '#fffafa',
  '#fffaf0',
  '#dcdcdc',
  '#f8f8ff',
  '#f0f8ff',
  '#faf0e6',
  '#d3d3d3',
  '#f5f5f5',
];

export const STATIC_DATA_TESTING = {
  data: {
    getLinkAnalytics: {
      clicks: {
        clicks: 1,
        monthWise: [
          {
            clicks: 0,
            month: 'october',
            year: '2023',
          },
          {
            clicks: 0,
            month: 'november',
            year: '2023',
          },
          {
            clicks: 1,
            month: 'december',
            year: '2023',
          },
        ],
      },
      countries: {
        count: 1,
        totalCountryCount: 12,
        country: [
          {
            country: 'India',
            count: 1,
            countryCode: 'IN',
          },
          {
            country: 'United states',
            count: 5,
            countryCode: 'US',
          },
          {
            country: 'Russia',
            count: 2,
            countryCode: 'RU',
          },
          {
            country: 'Brazil',
            count: 4,
            countryCode: 'BR',
          },
        ],
      },
      browsers: {
        count: 1,
        totalBrowserCount: 1,
        browser: [
          {
            browser: 'Chrome',
            count: 1,
          },
        ],
      },
      operatingSystems: {
        count: 1,
        totalOsCount: 1,
        operatingSystem: [
          {
            os: 'Linux 64',
            count: 1,
          },
        ],
      },
      devices: {
        count: 1,
        totalDeviceCount: 1,
        devices: [
          {
            count: 1,
            name: 'Desktop',
            platform: null,
            platformCount: 0,
          },
        ],
      },
    },
  },
};
export const MODAL_TYPE = {
  create: 'CREATE',
  update: 'UPDATE',
  delete: 'DELETE',
};
export const OK_BUTTON_TEXT = {
  CREATE: 'Create',
  UPDATE: 'Update',
  DELETE: 'Delete',
};

export const LISTING_CONSTANT = {
  skip: 0,
  limit: 10,
  total: 0,
};

export const FEATURE_ACCESS = {
  multipleSignature: 'MULTIPLE_SIGNATURE',
  analytics: 'ANALYTICS',
};

export const FEATURE_KEYS = {
  MULTIPLE_SIGNATURES: 'MULTIPLE_SIGNATURES',
  NO_BRANDING: 'NO_BRANDING',
  EMAIL_SIGNATURE: 'EMAIL_SIGNATURE',
  DOWNLOAD_HTML: 'DOWNLOAD_HTML',
  PRO_TEMPLATES: 'PRO_TEMPLATES',
  CALL_TO_ACTION: 'CALL_TO_ACTION',
  SIGNATURE_ANALYTICS: 'SIGNATURE_ANALYTICS',
  CLONE_SIGNATURE: 'CLONE_SIGNATURE',
  SHARE_VIA_EMAIL: 'SHARE_VIA_EMAIL',
  COMPANY_SIGNATURE_ANALYTICS: 'COMPANY_SIGNATURE_ANALYTICS',
  MULTIPLE_WORKSPACES: 'MULTIPLE_WORKSPACES',
  WHITE_LABEL: 'WHITE_LABEL',
  PROFILE_PICTURE_MAKER: 'PROFILE_PICTURE_MAKER',
  ROLES: 'ROLES',
  TEAM_FEATURE: 'TEAM_FEATURE',
  GOOGLE_MARKETPLACE: 'GOOGLE_MARKETPLACE',
  CUSTOM_FONT: 'CUSTOM_FONT',
  ADVANCE_FEATURE: 'ADVANCE_FEATURE',
};

export const onboardingStatus = {
  INFORMATION_ADDED: 'INFORMATION_ADDED',
  HEADSHOT_UPLOADED: 'HEADSHOT_UPLOADED',
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING',
};

export const antdConfigTheam = {
  token: {
    colorPrimary: '#7F56D9',
    radiusBase: '1px',
    controlRadius: '1px',
    fontFamily: "'Inter', sans-serif",
    colorLink: '#625B71',
    colorLinkActive: '#7F56D9',
    colorLinkHover: '#7F56D9',
  },
  components: {
    Typography: {
      fontSizeHeading3: 'clamp(20px, 4.6vw, 24px)',
    },
    Button: {
      borderRadius: 4,
      colorPrimary: 'rgb(127, 86, 217)',
      colorPrimaryHover: 'rgb(158, 130, 219)',
      colorTextDisabled: 'rgb(255, 255, 255)',
      colorBgContainerDisabled: 'rgb(176, 167, 192)',
    },
    Segmented: {
      itemSelectedBg: 'rgb(127, 86, 217)',
      colorBgLayout: 'rgb(232, 221, 255)',
      itemColor: 'rgba(0, 0, 0, 0.65)',
      itemSelectedColor: 'rgba(255, 255, 255, 0.88)',
      borderRadius: 50,
      borderRadiusSM: 100,
    },
    Input: {
      colorBgContainer: 'rgb(255, 255, 255)',
      colorError: 'rgb(238, 77, 55)',
      colorErrorBorderHover: 'rgb(238, 77, 55)',
      hoverBorderColor: 'rgb(243, 237, 255)',
      hoverBg: 'rgb(255, 255, 255)',
      activeBorderColor: 'rgb(127, 86, 217)',
      activeBg: 'rgb(255, 255, 255)',
      colorBorder: 'rgb(204, 194, 220)',
      borderRadius: 4,
    },
    Slider: {
      colorBgContainer: 'rgb(127, 86, 217)',
      colorPrimary: 'rgb(127, 86, 217)',
      trackBg: 'rgb(127, 86, 217)',
      dotActiveBorderColor: 'rgb(127, 86, 217)',
      dotBorderColor: 'rgb(127, 86, 217)',
      trackHoverBg: 'rgb(127, 86, 217)',
      handleColor: 'rgb(127, 86, 217)',
    },
    Progress: {
      remainingColor: 'rgba(247, 152, 152, 0.06)',
      defaultColor: 'rgb(127, 86, 217)',
    },
  },
};

export const HEADSHOT_DATA = {
  id: '',
  image: '',
  style: {},
  styleId: '',
  config: {
    'background-color': PRIMARY_COLOR,
    grayScale: null,
    output: null,
    processedImageKey: null,
  },
};

// export const getTemplateImages = (key) =>
//   `https://static.dev.sendsig.com/asset/${key}.png`;

export const getTemplateImages = (key, extension = 'png') =>
  `${process.env.REACT_APP_SOCIAL_ICONS_URL}/asset/${key}.${extension}`;

export const getSampleCSV = (key) =>
  `${process.env.REACT_APP_SOCIAL_ICONS_URL}/asset/${key}`;

export const UTAGSFIELDS = [
  'email',
  'website',
  'ctaUrl',
  'ctaBannerUrl',
  'facebook',
  'linkedin',
  'twitter',
  'youtube',
  'instagram',
  'tiktok',
  'pinterest',
  'github',
  'medium',
  'podcast',
  'skype',
  'tripadvisor',
  'vimeo',
  'flicker',
  'patreon',
  'rarible',
  'nextdoor',
  'behance',
  'angi',
  'linktree',
  'imdb',
];
export const IMAGE_TYPE = {
  gif: 'image/gif',
};

export const SIGN_OFF_OPTIONS = [
  { value: null, label: 'None' },
  { value: 'Custom Text', label: 'Custom Text' },
  { value: 'Happy Holidays!', label: 'Happy Holidays!' },
  { value: 'Best regards,', label: 'Best regards,' },
  { value: 'Sincerely,', label: 'Sincerely,' },
  { value: 'Regards,', label: 'Regards,' },
  { value: 'Best,', label: 'Best,' },
  { value: 'Kind regards,', label: 'Kind regards,' },
  { value: 'Thanks,', label: 'Thanks,' },
  { value: 'Best wishes,', label: 'Best wishes,' },
];
