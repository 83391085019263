import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { map } from 'lodash';
import { Button } from 'components/ui/button';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Badge } from 'components/ui/badge';
import { useGetRoleForWorkspace, useUserPermission } from 'hooks/user';
import { FEATURE_KEYS, ROLES } from 'common/constants';
import { TEMPLATE_ORDER, useFilteredTemplates } from 'hooks/template';
import { useRouter } from 'hooks/router';
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs';
import {
  CATEGORY_MAP_MODELS,
  CATEGORY_MODELS,
} from 'components/TemplatesModel/utils/constants';
import LoaderComponent from 'components/LoaderComponent';
import { dynamicTemplate } from './utils/dynamicTemplate';

const TemplatesModel = forwardRef(
  ({ handleTemplateSelect, dialogProps = {} }, ref) => {
    const { navigate } = useRouter();

    const [isOpen, setIsOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('All');
    useImperativeHandle(ref, () => ({
      open: () => setIsOpen(true),
      close: () => {
        setIsOpen(false);
        navigate('?');
      },
      toggle: () => setIsOpen(!isOpen),
    }));

    const { allowedTemplates, isFeaturesAllowed } = useUserPermission();
    const { templates, loading } = useFilteredTemplates();
    const isActive = () => false;
    const { role } = useGetRoleForWorkspace();

    const filteredData = templates.filter((template) => {
      if (!TEMPLATE_ORDER.includes(template?.template?.templateId)) {
        return false;
      }
      if (selectedCategory === 'All') {
        return true;
      }
      const templateCategories =
        CATEGORY_MAP_MODELS[template?.template?.templateId];
      if (!templateCategories) {
        return false;
      }
      const categories = templateCategories[template?.name];
      // const templateCategories = CATEGORY_MAP_MODELS[template?.name];
      if (!categories) {
        return false;
      }
      return Object.values(categories).some((categoryList) =>
        categoryList.includes(selectedCategory),
      );
    });

    const hideBranding =
      isFeaturesAllowed(FEATURE_KEYS.NO_BRANDING) ||
      role === ROLES.TEAMMATE ||
      role === ROLES.MANAGER;

    if (loading) {
      return <LoaderComponent />;
    }

    return (
      <div>
        {isOpen && (
          <Dialog
            open={isOpen}
            onOpenChange={ref?.current?.close}
            {...dialogProps}
          >
            <DialogContent
              className="max-w-[980px] max-h-[calc(100vh_-_100px)] w-full h-full p-0 rounded-[8px]
         flex flex-col bg-white-0"
              closeclass="top-6"
            >
              <DialogHeader className="w-full  h-[80px] rounded-t-[8px] bg-primary-foreground rounded-b-[0px]">
                <DialogTitle className=" !flex !justify-start items-center ml-4 h-[80px]">
                  Choose email signature template
                </DialogTitle>
              </DialogHeader>
              <div
                className="space-y-4 overflow-auto p-4"
                // style={{ columnCount: 2 }}
              >
                <Tabs
                  defaultValue="All"
                  onValueChange={(value) => setSelectedCategory(value)}
                >
                  <TabsList className="flex justify-center bg-transparent px-1 rounded-full mb-4">
                    {CATEGORY_MODELS.map((category) => (
                      <TabsTrigger
                        key={category}
                        value={category}
                        className="rounded-full flex items-center justify-center border-0 bg-transparent data-[state=active]:bg-primary data-[state=active]:text-white-0 cursor-pointer mr-4 p-4"
                      >
                        {category}
                      </TabsTrigger>
                    ))}
                  </TabsList>
                </Tabs>
                <div className="columns-2 gap-4">
                  {map(filteredData, (d, index) => {
                    const TemplateComponent = dynamicTemplate({
                      templateKey: d?.template?.key,
                    });
                    if (!TemplateComponent) return null;
                    return (
                      <div
                        key={index}
                        className={`relative mb-4 order-${index - 2}`}
                      >
                        <div
                          className={`headshot-border p-4 flex justify-start cursor-pointer overflow-x-hidden hover:inset-0 hover:shadow-template  hover:bg-primary-foreground hover:bg-opacity-100 hover:backdrop-blur-md break-inside-avoid rounded-[8px] w-auto h-full group relative ${
                            isActive(d?.id)
                              ? ' shadow-signature active-headshot'
                              : ''
                          } bg-white-0`}
                        >
                          {!allowedTemplates?.includes?.(
                            d.template.templateId,
                          ) && (
                            <Badge
                              className="absolute right-[10px] top-[15px]"
                              color="rgb(127, 86, 217)"
                            >
                              PRO
                            </Badge>
                          )}

                          <div className="w-full transform scale-[0.85] translate-x-[-30px] pointer-events-none">
                            {TemplateComponent && (
                              <TemplateComponent
                                signature={d}
                                hideBranding={hideBranding}
                              />
                            )}
                          </div>
                          <div className="hidden group-hover:flex absolute inset-0 items-center justify-center">
                            <Button
                              className="h-[40px] w-[137px]"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleTemplateSelect(d);
                              }}
                            >
                              Use Template
                            </Button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </DialogContent>
          </Dialog>
        )}
      </div>
    );
  },
);

export default TemplatesModel;
