import React from 'react';
import get from 'lodash/get';

function Footer({
  data,
  style = {},
  tdProps = {},
  signature = {},
  design = {},
}) {
  // Retrieve values from design or use default fallbacks
  const width = get(
    data,
    'width',
    get(design, 'styles.footer.style.width', '500px'),
  );
  const footerColor = get(design, 'styles.footer.style.color', '#7A7289');
  const footerFontSize = parseFloat(
    get(design, 'styles.footer.style.fontSize', '9px'),
  );
  const lineHeight = parseFloat(
    get(design, 'styles.font.style.lineHeight', '1.0'),
  );

  // Adjust font size based on line height
  const adjustedFontSize = footerFontSize * lineHeight;

  return (
    <td
      style={{
        color: footerColor,
        fontSize: `${adjustedFontSize}px`, // Apply adjusted font size
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: `${lineHeight * footerFontSize}px`,
        textAlign: 'left',
        width,
        paddingBottom: '16px',
        ...style,
      }}
      {...tdProps}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: data?.value }}
    />
  );
}

export default Footer;
