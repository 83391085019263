import React from 'react';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { Button } from 'components/ui/button';

function DataTableRowActions({ onRowClick }) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="flex h-6 w-6 p-0 data-[state=open]:bg-muted bg-gray-100">
          <DotsHorizontalIcon className="h-4 w-4 text-[#000000]" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        {/* <DropdownMenuItem>Copy Signature</DropdownMenuItem> */}
        <DropdownMenuItem
          onClick={() => {
            onRowClick({ type: 'send-signature' });
          }}
        >
          Send Signature
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            onRowClick({ type: 'send-invite' });
          }}
        >
          Send Invite
        </DropdownMenuItem>
        {/* <DropdownMenuSeparator /> */}

        {/* <DropdownMenuSeparator /> */}
        <DropdownMenuItem
          className="text-[#CF1322]"
          onClick={() => {
            onRowClick({ type: 'remove-from-group' });
          }}
        >
          Remove from Group
        </DropdownMenuItem>
      </DropdownMenuContent>

      <></>
    </DropdownMenu>
  );
}

export default DataTableRowActions;
